import { DatePipe, DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { URLS } from '../../_config/api.config';
import { ApiService } from '../../_services/api-data.service';
import { CommonService } from '../../_services/common.service';
import { ModalService } from '../../_services/modal.service';
import { ToastService } from '../../_services/toast.service';
import { ProductPreviewDetailComponent } from '../product-preview-detail/product-preview-detail.component';

@Component({
	selector: 'app-products-management',
	templateUrl: './products-management.component.html',
	styleUrls: ['./products-management.component.scss'],
})
export class ProductsManagementComponent implements OnInit {
	statusList = [
		{
			id: "approved",
			name: "Approved",
		},
		{
			id: "pending",
			name: "Pending",
		},
		{
			id: "rejected",
			name: "Rejected",
		},
	];
	statusListAr = [
		{
			id: "approved",
			name: "وافق"
		},
		{
			id: "pending",
			name: "قيد الانتظار"
		},
		{
			id: "rejected",
			name: "مرفوض"
		},
	];
	subClassesList = [];
	todaysDate = new Date();
	maxDate: any;
	currencySymbol: string = "";
	showSearch = false;
	showFilter = false;
	fromDate: any;
	toDate: any;
	searchText: string;
	public posts = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25];
	public itemsPerPage = 10;
	public currentPage = 1;
	columnHeaders = {
		order_id: 'Order #',
		date: 'Date',
		ship_to: 'Ship to',
		order_total: 'Order total',
		status: 'Status',
	}
	searchObj: any = {};
	productListData: any[] = [];
	perPageInterface = { cssClass: 'custom-select-dd per-page-dd' };
	selectInterface = { cssClass: 'custom-select-dd' };
	@Input() showHeader: boolean;
	showLoader: boolean = false;
	public totalProductCount: number = 0;
	private subscription: Subscription = new Subscription();
	selectStatus: any;
	fromPrice: any;
	toPrice: any;
	selectSubClass: any;
	selectBrand: any;
	pageno = 1;
	pagesize = 10;
	filterparams: any;
	dateRange: FormGroup
	maxFromDate: any;
	minToDate: any;
	brandList = [];
	constructor(
		private modalService: ModalService,
		public commonService: CommonService,
		private alertController: AlertController,
		private router: Router,
		private toastService: ToastService,
		private fb: FormBuilder,
		private targetElem: ElementRef,
		private dataService: ApiService,
		private datePipe: DatePipe,
		private modalCtrl: ModalController,
		@Inject(DOCUMENT) private document: Document,
	) { }

	ngOnInit() {
		const element = this.document.querySelector('#scrollId');
		if (element) {
			element.scrollIntoView();
		}
		this.getSubclass();
		this.getBrands();
		this.dateRange = this.fb.group({
			fromDate: new FormControl(null),
			toDate: new FormControl(null),
		})
		let date = new Date();
		this.maxFromDate = date.setDate(this.todaysDate.getDate() - 1);
		this.maxDate = this.datePipe.transform(this.todaysDate, 'YYYY-MM-dd');
		this.maxFromDate = this.datePipe.transform(this.maxFromDate, 'YYYY-MM-dd');

		this.searchText = null;
		this.filterparams = {
			page: this.pageno,
			size: this.pagesize
		}
		this.getProductList(this.filterparams, false);
	}

	ionViewWillEnter() {
		this.showHeader = true;
	}

	getProductList(params?, clearFilter?: boolean) {
		this.showLoader = true;
		if (this.searchObj) {
			params = { ...this.searchObj, ...params }
		}
		if (clearFilter) {
			params = {
				'page': 1,
				'size': this.pagesize
			}
		}
		this.subscription.add(
			this.dataService.get(URLS.productList, params).subscribe(data => {
				if (data['code'] == 200) {
					this.productListData = [];
					this.totalProductCount = parseInt(data['data']['total_count'])
					this.productListData = data['data']['result'];
					this.showLoader = false;
				} else {
					this.showLoader = false;
					this.toastService.displayToast(data['message'], null, 'danger');
				}
			}, error => {
				this.showLoader = false;
				this.productListData = [];
				this.toastService.displayToast(error['message'], null, 'danger');
			})
		)
	}

	getSubclass() {
		this.subscription.add(
			this.dataService.get(URLS.activeSubclass).subscribe(data => {
				if (data['code'] == 200) {
					let list = data['data'] && data['data'].length ? data['data'] : [];
					this.subClassesList = [];
					list.forEach(element => {
						this.subClassesList.push({
							id: element.sub_class_id,
							name: this.commonService.domDirectionLTR ? element.sub_class_name_en : element.sub_class_name_ar,
						})
					});

				} else {
					if (data['code'] != 204) {
						this.toastService.displayToast(data['message'], null, 'danger');
					}
				}
			}, error => {
				this.toastService.displayToast(error['message'], null, 'danger');
			})
		)
	}

	getBrands() {
		const params = {
			page: 0,
			limit: 0,
			is_active: true,
			dropdown: true,
		};
		this.subscription.add(
			this.dataService.get(URLS.brands, params).subscribe(data => {
				if (data['code'] == 200) {
					let list =
						data['data'] && data['data']['result'] && data['data']['result'].length ? data['data']['result'] : [];
					this.brandList = [];
					list.forEach((brand) => {
						let obj = {
							id: brand.brand_id ? brand.brand_id : '',
							name: this.commonService.domDirectionLTR ? brand.brand_name_en ? brand.brand_name_en : '' : brand.brand_name_ar ? brand.brand_name_ar : '',
						};
						this.brandList.push(obj);
					});
				} else {
					if (data['code'] != 204) {
						this.toastService.displayToast(data['message'], null, 'danger');
					}
				}
			}, error => {
				this.toastService.displayToast(error['message'], null, 'danger');
			})
		)
	}

	openFilters() {
		this.showFilter = !this.showFilter;
	}

	// search text parameter in api
	search() {
		this.pageno = 1;
		this.currentPage = 1;
		this.filterparams = {
			page: this.pageno,
			size: this.pagesize
		}
		if (this.searchText && this.searchText.trim() == '') {
			this.searchText = null;
			return
		}
		if (this.searchText) {
			this.filterparams['search'] = this.searchText.trim();
			this.getProductList(this.filterparams, false);
		} else {
			delete this.searchObj['search'];
			delete this.filterparams['search'];
		}
	}

	//pagination changes the page
	onChange(event): void {
		this.currentPage = event;
		this.pageno = event;
		this.filterparams['page'] = this.pageno;
		this.getProductList(this.filterparams, false);
	}

	async presentAlert(productId) {
		const alert = await this.alertController.create({
			header: 'Are you sure?',
			message: 'You want to edit product?',
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
					handler: () => {
					},
				},
				{
					text: 'OK',
					role: 'confirm',
					handler: () => {
						this.router.navigate(['account-details/products-management/product-detail', productId]);
					},
				},
			],
		});
		await alert.present();
		const { role } = await alert.onDidDismiss();
	}

	editProductDetails(product_id: any) {
		this.router.navigate(['/account-details/products-management/product/edit/product-detail', product_id]);
	}

	viewProductDetails(product_id: any) {
		this.router.navigate(['/account-details/products-management/view-product', product_id])
	}

	applyBtn() {
		this.searchObj = {}
		this.currentPage = 1;
		this.searchObj['page'] = 1;
		this.searchObj['size'] = this.pagesize;
		if (this.selectStatus) {
			this.searchObj['status'] = this.selectStatus;
		}
		if ((this.fromDate && !this.toDate) || (!this.fromDate && this.toDate)) {
			if (!this.fromDate) {
				let msg = this.commonService.domDirectionLTR ? "Please select 'From Date'." : 'الرجاء تحديد "من التاريخ".';
				this.toastService.displayToast(msg, null, 'danger');
			}
			if (!this.toDate) {
				let msg = this.commonService.domDirectionLTR ? "Please select 'To Date'." : 'الرجاء تحديد "حتى الآن".';
				this.toastService.displayToast(msg, null, 'danger');
			}
			return
		}
		if (((this.fromPrice === null || this.fromPrice === undefined)) || ((this.toPrice === null || this.toPrice === undefined))) {
			if (this.toPrice && (this.fromPrice === null || this.fromPrice === undefined)) {
				let msg = this.commonService.domDirectionLTR ? "Please select 'From Price'." : 'الرجاء تحديد "من السعر".';
				this.toastService.displayToast(msg, null, 'danger');
				return
			}
			if ((this.fromPrice >= 0 && this.fromPrice !== null) && (this.toPrice === null || this.toPrice === undefined)) {
				let msg = this.commonService.domDirectionLTR ? "Please select 'To Price'." : 'الرجاء تحديد "السعر".';
				this.toastService.displayToast(msg, null, 'danger');
				return
			}
		}
		if (this.fromDate && this.toDate) {
			this.searchObj['from_date'] = this.datePipe.transform(this.fromDate, 'yyyy-MM-dd');
			this.searchObj['to_date'] = this.datePipe.transform(this.toDate, 'yyyy-MM-dd');
		}
		if (this.fromPrice >= 0 && this.toPrice >= 0 && this.fromPrice !== null && this.toPrice !== null) {
			this.searchObj['price_range_from'] = this.fromPrice;
			this.searchObj['price_range_to'] = this.toPrice;
		}
		if (this.searchObj['price_range_from'] >= 0 && this.searchObj['price_range_to'] >= 0 && (this.searchObj['price_range_from'] !== null && this.searchObj['price_range_to'] !== null) && (this.searchObj['price_range_from'] === this.searchObj['price_range_to'])) {
			let msg = this.commonService.domDirectionLTR ? "'From Price' and 'To Price' cannot be same." : 'لا يمكن أن يكون من السعر إلى السعر نفس الشيء.';
			this.toastService.displayToast(msg, null, 'danger');
			return
		}
		if (this.searchObj['price_range_from'] >= 0 && this.searchObj['price_range_to'] >= 0 && (this.searchObj['price_range_from'] > this.searchObj['price_range_to'])) {
			let msg = this.commonService.domDirectionLTR ? "'From Price' cannot be greater than 'To Price' ." : 'لا يمكن أن يكون "من السعر" أكبر من "إلى السعر".';
			this.toastService.displayToast(msg, null, 'danger');
			return
		}
		if (this.selectBrand && this.selectBrand.length) {
			this.searchObj['brand_id'] = this.selectBrand.join(',');
		}
		if (this.selectSubClass && this.selectSubClass.length) {
			this.searchObj['sub_class_id'] = this.selectSubClass.join(',');
		}
		this.getProductList(this.searchObj, false)
	}

	// select data per page
	selectPageLength(event) {
		let selectPageSize = event.detail.value;
		this.itemsPerPage = event.detail.value
		this.pagesize = selectPageSize;
		this.currentPage = 1;
		this.filterparams['page'] = 1;
		this.filterparams['size'] = selectPageSize;
		this.getProductList(this.filterparams, false)
	}

	// title case to status
	titlestatus(str) {
		if (str) {
			str = str.toLowerCase().split(' ');
			for (var i = 0; i < str.length; i++) {
				str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
			}
			return str.join(' ');
		}
		return str
	}

	clearSearch() {
		this.searchText = null;
		delete this.searchObj['search'];
		delete this.filterparams['search'];
		let params = {
			page: 1,
			size: this.pagesize
		}
		this.pageno = 1;
		this.currentPage = 1;
		this.getProductList(params, false);
	}

	clearFilter() {
		this.selectStatus = null;
		this.fromDate = null;
		this.toDate = null;
		this.fromPrice = null;
		this.toPrice = null;
		this.selectSubClass = null;
		this.selectBrand = null;
		this.searchObj = {};
		this.searchObj['page'] = 1;
		this.searchObj['size'] = this.pagesize;
		setTimeout(() => {
			this.getProductList(this.searchObj, true);
		}, 300);
	}

	priceClick(e) {
		if (e.keyCode < 48 || e.keyCode > 57) {
			e.preventDefault();
		}
	}

	onDateChange(datetime) {
		setTimeout(() => {
			datetime.cancel(true);
		}, 300)
	}

	key: string = "id";
	sortType: string = "ASC";

	onSort(event, key: any) {

		if (!this.productListData.length) {
			return;
		}
		this.key = key;

		const element = document.querySelectorAll('.sorting-icon');
		element.forEach(img => {
			img.setAttribute("src", "assets/icon/sort.svg")
		});

		let eventValue;
		if (event.target.className.includes("column-heading")) {
			eventValue = event.target.children[0];
		} else {
			eventValue = event.srcElement;
		}
		let order = eventValue.getAttribute("data-order")
		if (this.searchObj['sort_by'] != key) {
			order = "asc"
		}
		if (order === "asc") {
			this.sortType = "ASC";
			eventValue.setAttribute("data-order", "desc");
			eventValue.src = "assets/icon/sorting_asc.svg";
		} else if (order === "desc") {
			this.sortType = "DESC";
			eventValue.setAttribute("data-order", "");
			eventValue.src = "assets/icon/sorting_desc.svg";
		} else {
			delete this.searchObj['sort_by'];
			delete this.searchObj['sort_type'];
			eventValue.setAttribute("data-order", "asc");
			eventValue.src = "assets/icon/sort.svg";

		}
		if (order) {
			this.searchObj['sort_by'] = key;
			this.searchObj['sort_type'] = this.sortType;
		}

		this.searchObj['page'] = 1;
		this.searchObj['size'] = this.pagesize;
		if (this.searchText) {
			this.searchObj['search'] = this.searchText.trim();
		} else {
			delete this.searchObj['search'];
			delete this.filterparams['search'];
		}
		this.getProductList(this.searchObj, false)
	}

	preview(id) {
		if (!id) {
			return
		}
		let obj = {
			component: ProductPreviewDetailComponent,
			backdropDismiss: false,
			cssClass: 'preview-modal',
			componentProps: {
				product_id: id
			},
		};
		const modal = this.modalService.openModal(obj);
		modal.then(value => {
		}, error => {
			console.log(error);
		});
	}

	async presentDeleteAlert(id) {
		const alert = await this.alertController.create({
			header: 'Are you sure you want to delete this product?',
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
					cssClass: 'secondary',
					handler: () => {
					},
				},
				{
					text: 'OK',
					role: 'confirm',
					handler: () => {
						this.removeProduct(id);
					},
				},
			],
		});

		await alert.present();

		const { role } = await alert.onDidDismiss();

	}

	removeProduct(id) {
		let params = {};
		params = {
			product_details: [
				{
					product_id: id,
				},
			],
		};
		params['product_details'][0]['is_deleted'] = true;
		this.subscription.add(
			this.dataService.post(URLS.updateProduct, params).subscribe(
				(data) => {
					if (data['code'] == 200) {
						this.getProductList();
					} else {
						if (data['code'] != 204) {
							this.toastService.displayToast(data['message'], null, 'danger');
						}
					}
				},
				(error) => {
					this.toastService.displayToast(error['message'], null, 'danger');
				}
			)
		);

	}

}
