import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { CustomValidator } from '../../_common/customvalidators';
import { URLS } from '../../_config/api.config';
import { ApiService } from '../../_services/api-data.service';
import { CommonService } from '../../_services/common.service';
import { ModalService } from '../../_services/modal.service';
import { ToastService } from '../../_services/toast.service';

@Component({
	selector: 'app-add-discount',
	templateUrl: './add-discount.component.html',
	styleUrls: ['./add-discount.component.scss'],
})
export class addDiscountComponent implements OnInit {
	public addDiscountForm: FormGroup;
	public subscription = new Subscription();
	@Input() discountData;
	@Input() dataFrom;
	@Input() fromPage;
	modalTitle: any;
	discountEndDate: any;
	discountStartDate: any;
	selectSubClass: any;
	discountId: any;
	editData: any;
	isLoading = false;
	allSelected = false;
	subClassesList = [];
	subClassProductList = [];
	productIdList = [];
	files: any;
	apiUrl: any;
	public message: any;
	public fileToUpload: any
	discountLogoFile: any;
	discountLogoPreview: any;
	discountLogoName: any;
	isDiscountLogoFile = false;
	showLoader: boolean = false;
	datePipe = new DatePipe("en-US");
	selectInterface = { cssClass: 'custom-select-dd' };
	minDate: any;
	todaysDate = new Date();
	allowSelection = true;
	public totalItems = 0;
	public addDiscountItemsPerPage = 5;
	public addDiscountCurrentPage = 1;
	public pageno = 1;
	discountArray = [
		{ value: 'PERCENTAGE', name: this.commonService.domDirectionLTR ? 'PERCENTAGE' : 'نسبة مئوية' },
		{ value: 'PRICE', name: this.commonService.domDirectionLTR ? 'PRICE' : 'سعر' }
	];

	public validation_messages = {
		discount_name_en: [
			{ type: 'required', message: 'addDiscountComponent.errors.nameRequired' },
			{ type: 'invalidName', message: 'addDiscountComponent.errors.nameInvalid' },
			{ type: 'minlength', message: 'addDiscountComponent.errors.nameMinLength' },
			{ type: 'maxlength', message: 'addDiscountComponent.errors.nameMaxLength' },
		],
		discount_name_ar: [
			{ type: 'required', message: 'addDiscountComponent.errors.nameArRequired' },
			{ type: 'invalidName', message: 'addDiscountComponent.errors.invalidNameAr' },
		],
		description_en: [
			{ type: 'required', message: 'addDiscountComponent.errors.descriptionRequire' },
			{ type: 'invalidName', message: 'addDiscountComponent.errors.invalidDescription' },
		],
		description_ar: [
			{ type: 'required', message: 'addDiscountComponent.errors.descriptionArRequired' },
			{ type: 'invalidName', message: 'addDiscountComponent.errors.invalidDescriptionAr' },
		],
		discount: [
			{ type: 'required', message: 'addDiscountComponent.errors.discountRequire' },
			{ type: 'invalidName', message: 'addDiscountComponent.errors.invalidDiscount' },
			{ type: 'min', message: 'addDiscountComponent.errors.invalidDiscount' },
			{ type: 'max', message: 'addDiscountComponent.errors.maxDiscount' },
		],
		subclass: [
			{ type: 'required', message: 'addDiscountComponent.errors.subclassRequire' },
		],
		discount_startdate: [
			{ type: 'required', message: 'addDiscountComponent.errors.startdateRequire' }
		],
		discount_enddate: [
			{ type: 'required', message: 'addDiscountComponent.errors.enddateRequire' }
		],
		discountLogoName: [
			{ type: 'required', message: 'addDiscountComponent.errors.discountLogoRequire' }
		],
		discount_type: [
			{ type: 'required', message: 'addDiscountComponent.errors.discountTypeRequire' }
		],
	}

	constructor(
		private formBuilder: FormBuilder,
		private router: Router,
		private toastService: ToastService,
		public commonService: CommonService,
		private modalService: ModalService,
		private modalCtrl: ModalController,
		private dataService: ApiService,
		private alertController: AlertController,
		private activeRoute: ActivatedRoute,
		private translate: TranslateService
	) { }

	ngOnInit() {
		this.minDate = this.datePipe.transform(this.todaysDate, 'YYYY-MM-dd');

		this.addDiscountForm = this.initializeForm();
		this.getSubclass();
		this.discountId = this.discountData;
		if (this.discountId) {
			this.modalTitle = this.translate.instant('editDiscount');
			this.allowSelection = false;
			this.getProductDiscount(this.discountId);
		} else {
			this.modalTitle = this.translate.instant('addDiscount');
			this.allowSelection = true;
			this.addDiscountForm.controls.subclass.disable();
		}
	}

	ionViewWillEnter() {
		this.subscription = new Subscription();
		this.addDiscountForm = this.initializeForm();
	}

	initializeForm(data = null) {
		if (data) {
			if (data.start_date) {
				this.discountStartDate = this.datePipe.transform(data.start_date, 'YYYY-MM-dd');
			}
			if (data.end_date) {
				this.discountEndDate = this.datePipe.transform(data.end_date, 'YYYY-MM-dd');
			}
		}
		return this.formBuilder.group({
			discount_name_en: new FormControl(data && data.discount_title_en ? data.discount_title_en : null, {
				validators: Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(50), CustomValidator.alphanumericValidator]),
			}),
			discount_name_ar: new FormControl(data && data.discount_title_ar ? data.discount_title_ar : null, {
				validators: Validators.compose([Validators.required, CustomValidator.arabicNameValidator]),
			}),
			description_en: new FormControl(data && data.discount_description_en ? data.discount_description_en : null, {
				validators: Validators.compose([Validators.required]),
			}),
			description_ar: new FormControl(data && data.discount_description_ar ? data.discount_description_ar : null, {
				validators: Validators.compose([Validators.required, CustomValidator.arabicNameValidator]),
			}),
			discount: new FormControl(data && data.discount_value ? data.discount_value : null, {
				validators: Validators.compose([Validators.required, Validators.min(1)]),
			}),
			subclass: new FormControl(null, {
				validators: Validators.compose([Validators.required]),
			}),
			discount_type: new FormControl(data && data.discount_type ? data.discount_type : null, {
				validators: Validators.compose([Validators.required]),
			}),
			discount_start_date: new FormControl(data && data.start_date ? data.start_date : null, {
				validators: Validators.compose([Validators.required]),
			}),
			discount_startdate: new FormControl(data && data.start_date ? data.start_date : null, {
				validators: Validators.compose([Validators.required]),
			}),
			discount_end_date: new FormControl(data && data.end_date ? data.end_date : null, {
				validators: Validators.compose([Validators.required]),
			}),
			discount_enddate: new FormControl(data && data.end_date ? data.end_date : null, {
				validators: Validators.compose([Validators.required]),
			}),
			discountLogoName: new FormControl(data && data.image_url ? data.image_url : null, {
				validators: Validators.compose([]),
			}),

		});
	}

	upload(files) {
		files.preventDefault();
		files.stopPropagation();

		this.files = files['target']['files'][0].name;
		if (files && files['target'] && files['target']['files'] && files['target']['files'][0]) {

			// for max size 2MB
			const max_size = 2097152;
			if (files['target']['files'][0].size > max_size) {
				this.message =
					this.translate.instant('error.maxImage') + max_size / 1048576 + 'MB';
				this.files = null;
				this.fileToUpload = null;
				files.target.value = null;
				this.toastService.displayToast(this.message, null, 'warning');
				return false;
			}
		}

		if (files['target']['files'][0].type == "image/png") {
			this.message = null;
			const reader = new FileReader();
			reader.readAsDataURL(files['target']['files'][0]);
			reader.onload = (event: any) => {
				this.discountLogoPreview = event.target.result;
			};

			this.discountLogoFile = files['target']['files'][0];
			this.discountLogoName = files['target']['files'][0].name;
			this.isDiscountLogoFile = false;
		} else {
			this.message = 'Only png images are allowed!';
			this.fileToUpload = null;
			files.target.value = null;
			this.files = null;
			this.toastService.displayToast(this.translate.instant('error.onlyPng'), null, 'warning');
			return false;
		}

		files.target.value = '';

	}

	remove(event) {
		event.preventDefault();
		event.stopPropagation();
		this.discountLogoFile = null;
		this.discountLogoName = null;
		this.discountLogoPreview = null;
	}

	getSubclass() {
		this.subscription.add(
			this.dataService.get(URLS.subClassList).subscribe(data => {
				if (data['code'] == 200) {
					let list = data['data'] && data['data'].length ? data['data'] : [];
					this.subClassesList = [];
					list.forEach(element => {
						this.subClassesList.push({
							id: element.sub_class_id,
							name: this.commonService.domDirectionLTR ? element.sub_class_name_en ? element.sub_class_name_en : '-' : element.sub_class_name_ar ? element.sub_class_name_ar : '-'
						})
					});

				} else {
					if (data['code'] != 204) {
						this.toastService.displayToast(data['message'], null, 'danger');
					}
				}
			}, error => {
				this.toastService.displayToast(error['message'], null, 'danger');
			})
		)
	}

	selectCheckbox(event: any, productId: any) {
		const isChecked = event.detail.checked;
		if (isChecked) {
			if (!this.productIdList.includes(productId)) {
				this.productIdList.push(productId);
			}
		} else {
			const index = this.productIdList.indexOf(productId);
			if (index > -1) {
				this.productIdList.splice(index, 1);
			}
		}

		if (this.productIdList.length === this.subClassProductList.length) {
			this.allSelected = true;
		} else {
			this.allSelected = false;
		}
	}

	allSelectedCheckbox(event: any) {
		setTimeout(() => {
			if (this.allSelected) {
				this.subClassProductList.forEach(element => {
					element.isChecked = true;
					if (!this.productIdList.includes(element.product_id)) {
						this.productIdList.push(element.product_id);
					}
				});
			} else {
				this.productIdList = [];
				this.subClassProductList.forEach(element => {
					element.isChecked = false;
				});
			}
		});
	}

	onSubClassChange(list: any) {
		const subclassList = [];
		list.forEach((item) => {
			subclassList.push(item.id);
		});
		if (subclassList.length > 0) {
			this.getProductsList(subclassList)
		} else {
			this.subClassProductList = [];
		}
		this.allSelected = false;
	}

	getProductsList(id: any) {
		const params = {
			sub_class_id: id.toString(),
			start_date: this.datePipe.transform(this.addDiscountForm.value.discount_start_date, 'YYYY-MM-dd'),
			end_date: this.datePipe.transform(this.addDiscountForm.value.discount_end_date, 'YYYY-MM-dd')

		};
		this.showLoader = true;
		this.subscription.add(
			this.dataService.get(URLS.subClassProduct, params).subscribe(
				(data) => {
					if (data['code'] == 200) {
						if (data['data']) {
							this.subClassProductList = [];
							let list = data['data'] && data['data'].length ? data['data'] : [];
							if (data['data'] && data['data'].length) {
								list.forEach((product) => {
									let obj = {
										product_id: product['product_id'],
										sub_class_id: product['sub_class_id'],
										supplier_id: product['supplier_id'],
										product_name: this.commonService.domDirectionLTR ? product.product_name_en ? product.product_name_en : '-' : product.product_name_ar ? product.product_name_ar : '-',
										total_quantity: product['total_quantity'],
										product_sku: product['product_sku'],
										price_per_unit: product['price_per_unit'],
										category_name: this.commonService.domDirectionLTR ? product.category_name_en ? product.category_name_en : '-' : product.category_name_ar ? product.category_name_ar : '-',
										sub_class_name: this.commonService.domDirectionLTR ? product.sub_class_name_en ? product.sub_class_name_en : '-' : product.sub_class_name_ar ? product.sub_class_name_ar : '-',
										created_at: product['created_at'],
										updated_at: product['updated_at'],
										web_image_url: product['web_image_url'],
										mobile_image_url: product['mobile_image_url'],
									};
									this.subClassProductList.push(obj);
								});
							}
							this.showLoader = false;
						}
					} else {
						if (data['code'] != 204) {
							this.showLoader = false;
							this.toastService.displayToast(data['message'], null, 'danger');
						}
					}
				},
				(error) => {
					this.showLoader = false;
					this.toastService.displayToast(error['message'], null, 'danger');
				}
			)
		);
	}

	getProductDiscount(id: any) {
		const params = {
			discount_id: Number(id),
		};
		this.subscription.add(
			this.dataService.get(URLS.discountDetails, params).subscribe(
				(data) => {
					if (data['code'] == 200) {
						if (data['data']) {

							this.editData = {
								discount_id: data['data']['discount_id'],
								discount_description_en: data['data']['discount_description_en'],
								discount_description_ar: data['data']['discount_description_ar'],
								discount_title_en: data['data']['discount_title_en'],
								discount_title_ar: data['data']['discount_title_ar'],
								start_date: this.datePipe.transform(data['data']['start_date'], 'MMM dd,yyyy'),
								end_date: this.datePipe.transform(data['data']['end_date'], 'MMM dd,yyyy'),
								discount_value: data['data']['discount_value'],
								discount_type: data['data']['discount_type'],
								sub_class_id: data['data']['sub_class_id'],
								image_url_en: data['data']['image_url_en'],
								image_url_ar: data['data']['image_url_ar'],
								image_url: data['data']['image_url_en'],
								discounted_value: data['data']['discounted_value'],
								discounted_product_id: data['data']['discounted_product_id'],
								product: data['data']['product'] && data['data']['product'].length ? data['data']['product'] : [],
							}
							this.discountStartDate = data['data']['start_date'];
							this.discountEndDate = data['data']['end_date'];
							this.addDiscountForm = this.initializeForm(this.editData);
							if (this.editData.product) {
								let selectedSubclass = [];
								this.subClassProductList = [];
								this.productIdList = [];
								this.editData.product.forEach(product => {
									this.productIdList.push(product.product_id)
									if (!selectedSubclass.includes(product.sub_class_id)) {
										selectedSubclass.push(product.sub_class_id)
									}
									let obj = {
										product_id: product['product_id'],
										sub_class_id: product['sub_class_id'],
										supplier_id: product['supplier_id'],
										product_name: this.commonService.domDirectionLTR ? product.product_name_en ? product.product_name_en : '-' : product.product_name_ar ? product.product_name_ar : '-',
										total_quantity: product['total_quantity'],
										product_sku: product['product_sku'],
										price_per_unit: product['price_per_unit'],
										category_name: this.commonService.domDirectionLTR ? product.category_name_en ? product.category_name_en : '-' : product.category_name_ar ? product.category_name_ar : '-',
										sub_class_name: this.commonService.domDirectionLTR ? product.sub_class_name_en ? product.sub_class_name_en : '-' : product.sub_class_name_ar ? product.sub_class_name_ar : '-',
										created_at: product['created_at'],
										updated_at: product['updated_at'],
										web_image_url: product['web_image_url'],
										mobile_image_url: product['mobile_image_url'],
										discounted_product_id: product['discounted_product_id'],
										discounted_product_price: product['discounted_value']
									};

									this.subClassProductList.push(obj)
								});
								if (this.subClassProductList.length) {
									this.allowSelection = false;
									this.addDiscountForm.controls.subclass.setValue(selectedSubclass);
									this.addDiscountForm.controls.subclass.disable();
								} else {
									this.allowSelection = true;
								}
								this.typeChange();

							}

							if (this.editData.image_url) {
								this.discountLogoPreview = this.editData.image_url;
								let discountLogoData = this.discountLogoPreview.split("/");
								this.discountLogoName = discountLogoData[discountLogoData.length - 1].replace(".webp", "");
							}
						}
					} else {
						if (data['code'] != 204) {
							this.toastService.displayToast(data['message'], null, 'danger');
						}
					}
				},
				(error) => {
					this.toastService.displayToast(error['message'], null, 'danger');
				}
			)
		);
	}

	restrictSpecialChar(event) {
		var k;
		k = event.charCode;  //         k = event.keyCode;  (Both can be used)
		return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
	}

	onSubmit() {
		if (!this.discountId) {
			this.isDiscountLogoFile = true;
			if (this.discountLogoFile) this.isDiscountLogoFile = false;
		}

		if (this.discountStartDate && this.discountEndDate) {
			this.addDiscountForm.controls['discount_start_date'].setValue(this.discountStartDate);
			this.addDiscountForm.controls['discount_startdate'].setValue(this.discountStartDate);
			this.addDiscountForm.controls['discount_end_date'].setValue(this.discountEndDate);
			this.addDiscountForm.controls['discount_enddate'].setValue(this.discountEndDate);
		}
		for (const i in this.addDiscountForm.controls) {
			this.addDiscountForm.controls[i].markAsDirty();
			this.addDiscountForm.controls[i].updateValueAndValidity();
			this.addDiscountForm.controls[i].markAsTouched();
		}
		if (this.addDiscountForm.invalid) {
			return;
		}

		if (this.discountStartDate && this.discountEndDate && (this.discountStartDate > this.discountEndDate)) {
			this.toastService.displayToast('error.startDateGreater', null, 'danger');
			return;
		}
		if (!this.discountLogoFile && !this.discountId) return;
		if (this.productIdList.length == 0) {
			this.toastService.displayToast("error.selectOneProduct", null, 'danger');
			return
		}

		const params = new FormData();
		if (this.discountId) {
			params.append('discount_id', this.discountId);
			this.apiUrl = URLS.discountUpdate;
		} else {
			this.apiUrl = URLS.discountAdd;
		}
		if (this.addDiscountForm.value.discount) {
			params.append('discount_value', this.addDiscountForm.value.discount);
		}
		if (this.addDiscountForm.value.discount_start_date) {
			params.append('start_date', this.datePipe.transform(this.addDiscountForm.value.discount_start_date, 'YYYY-MM-dd'));
		}
		if (this.addDiscountForm.value.discount_end_date) {
			params.append('end_date', this.datePipe.transform(this.addDiscountForm.value.discount_end_date, 'YYYY-MM-dd'));
		}
		if (this.productIdList) {
			params.append('product_id', JSON.stringify(this.productIdList));
		}
		if (this.addDiscountForm.value.discount_type) {
			params.append('discount_type', this.addDiscountForm.value.discount_type);
		}
		if (this.addDiscountForm.value.discount_name_en) {
			params.append('discount_title_en', this.addDiscountForm.value.discount_name_en);
		}
		if (this.addDiscountForm.value.discount_name_ar) {
			params.append('discount_title_ar', this.addDiscountForm.value.discount_name_ar);
		}
		if (this.addDiscountForm.value.description_en) {
			params.append('discount_description_en', this.addDiscountForm.value.description_en);
		}
		if (this.addDiscountForm.value.description_ar) {
			params.append('discount_description_ar', this.addDiscountForm.value.description_ar);
		}
		if (this.discountLogoFile && this.commonService.domDirectionLTR) {
			params.append('image_url_en', this.discountLogoFile);
		} else if (this.discountLogoFile) {
			params.append('image_url_ar', this.discountLogoFile);
		}


		this.isLoading = true;
		this.subscription.add(
			this.dataService.post(this.apiUrl, params).subscribe(data => {
				if (data['code'] == 200 && data['data']) {
					this.isLoading = false;
					this.close(true);
				} else {
					this.isLoading = false;
					this.toastService.displayToast(data['message'], null, 'danger');
				}
			}, error => {
				this.isLoading = false;
				this.toastService.displayToast(error['message'], null, 'danger');
			})
		)
	}

	close(updated?) {
		this.modalCtrl.dismiss(updated);
	}

	onDateChange(datetime) {
		if (this.discountEndDate && this.discountStartDate) {
			this.addDiscountForm.controls.subclass.enable();
		} else {
			this.addDiscountForm.controls.subclass.disable();
		}
		this.addDiscountForm.controls.subclass.updateValueAndValidity();
		setTimeout(() => {
			datetime.cancel(true);
		}, 300)
	}

	async presentAlert(discountedProductId, index?) {

		const alert = await this.alertController.create({
			header: this.translate.instant('areYouSure'),
			message: this.translate.instant('deleteProductMsg'),
			buttons: [
				{
					text: this.translate.instant('Cancel'),
					role: 'cancel',
					handler: () => {

					},
				},
				{
					text: this.translate.instant('yes'),
					role: 'confirm',
					handler: () => {
						let params = {
							discounted_product_id: +discountedProductId,
						};
						this.deleteProduct(params, index);
					},
				},
			],
		});
		await alert.present();
		const { role } = await alert.onDidDismiss();
	}

	/////////// delete product data /////////////
	deleteProduct(params, index) {

		this.subscription.add(
			this.dataService.put(URLS.deleteDiscountedProduct, params).subscribe(data => {
				if (data['code'] == 200 && data['data']) {
					this.subClassProductList.splice(index, 1);
					let pos = this.productIdList.indexOf(+params.discounted_product_id)
					this.productIdList.splice(pos, 1);
					if (this.subClassProductList.length && this.productIdList.length) {
						this.allowSelection = false;
					} else {
						this.allowSelection = true;
						this.addDiscountForm.controls.subclass.reset();
						this.addDiscountForm.controls.subclass.enable();
					}
				} else {
					this.toastService.displayToast(data['message'], null, 'danger');
				}
			}, error => {
				this.toastService.displayToast(error['message'], null, 'danger');
			})
		)
	}

	ionViewWillLeave() {
		this.subscription.unsubscribe();
	}

	numberOnlyValidation(event: any) {
		const pattern = /[0-9]/;
		let inputChar = String.fromCharCode(event.charCode);

		if (!pattern.test(inputChar)) {
			// invalid character, prevent input
			event.preventDefault();
		}
	}

	decimalCheck(event, oneDecimal?) {
		var charCode = event.which ? event.which : event.keyCode;
		if (
			charCode > 31 &&
			(charCode < 48 || charCode > 57) &&
			!(charCode == 46 || charCode == 8)
		) {
			return false;
		}
		if (event.key == '.' && event.target.value.includes(event.key)) {
			return false;
		}
		var dotPos = event.target.value.indexOf('.');
		if (
			oneDecimal &&
			event.target.value.includes('.') &&
			dotPos == event.target.value.length - 2
		) {
			return false;
		}
		if (
			event.target.value.includes('.') &&
			dotPos == event.target.value.length - 3
		) {
			return false;
		}
		return true;
	}

	typeChange() {
		if (this.addDiscountForm.value.discount_type == 'PERCENTAGE') {
			this.addDiscountForm.controls.discount.setValidators([Validators.max(100), Validators.min(1), Validators.required])
		} else {
			this.addDiscountForm.controls.discount.clearValidators();
			this.addDiscountForm.controls.discount.setValidators([Validators.required, Validators.min(1)])

		}
		this.addDiscountForm.controls.discount.updateValueAndValidity();
	}

	onChange(event): void {
		this.addDiscountCurrentPage = event;
		this.pageno = event;

	}
}
