import { DOCUMENT, DatePipe } from '@angular/common';
import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/_services/api-data.service';
import { ExportExcelService } from 'src/app/_services/export-excel.service';
import { URLS } from '../../_config/api.config';
import { CommonService } from '../../_services/common.service';
import { ModalService } from '../../_services/modal.service';
import { ToastService } from '../../_services/toast.service';

@Component({
	selector: 'app-my-transaction-list',
	templateUrl: './my-transaction-list.component.html',
	styleUrls: ['./my-transaction-list.component.scss'],
})
export class MyTransactionListComponent implements OnInit {
	public subscription = new Subscription();
	showSearch = false;
	showFilter: boolean = false;
	isLoading: boolean = false;
	isDownloading: boolean = false;
	perPageInterface = { cssClass: 'custom-select-dd per-page-dd' };

	public count = 0;
	public totalCount: any = 0;

	public itemsPerPage = 5;
	key: any;
	public currentPage = 1;
	filterparams: any = {};
	sortType: string = 'ASC';
	pageno = 1;
	pagesize = '10';

	transactionList = [];

	dateRange: FormGroup;
	statusList = [];
	startDate: any;
	endDate: any;
	maxFromDate: any;
	minToDate: any;
	todaysDate = new Date();
	maxDate: any;
	statusFilter = 'all';
	searchText: any;
	constructor(
		private modalService: ModalService,
		public commonService: CommonService,
		private fb: FormBuilder,
		private datePipe: DatePipe,
		private targetElem: ElementRef,
		private router: Router,
		private dataService: ApiService,
		private toastService: ToastService,
		private translate: TranslateService,
		private exportService: ExportExcelService,
		@Inject(DOCUMENT) private document: Document
	) { }

	ngOnInit() {
		this.initializeData();
	}
	initializeData() {
		this.getTransactionList();
		const element = this.document.querySelector('#scrollId');
		if (element) {
			element.scrollIntoView();
		}
		this.dateRange = this.fb.group({
			fromDate: new FormControl(null),
			toDate: new FormControl(null),
		});
		const date = new Date();
		this.maxFromDate = date.setDate(this.todaysDate.getDate() - 1);
		this.maxDate = this.datePipe.transform(this.todaysDate, 'YYYY-MM-dd');
		this.maxFromDate = this.datePipe.transform(this.maxFromDate, 'YYYY-MM-dd');

		this.statusList = [
			{
				value: 'all',
				label: this.translate.instant('all'),
			},
			{
				value: 'DISPATCHED',
				label: this.translate.instant('dispatched'),
			},
			{
				value: 'DELIVERED',
				label: this.translate.instant('delivered'),
			},
			{
				value: 'NOT_YET_DELIVERED',
				label: this.translate.instant('notYetDelivered'),
			},
			{
				value: 'CANCELLATION_REQUESTED',
				label: this.translate.instant('cancellationRequested'),
			},
			{
				value: 'CANCELLED',
				label: this.translate.instant('canceled'),
			},
			{
				value: 'RETURN_REQUESTED',
				label: this.translate.instant('returnRequested'),
			},
			{
				value: 'RETURNED',
				label: this.translate.instant('returned'),
			},
		];
		this.searchText = null;
	}

	ionViewWillEnter() {
		this.initializeData();
	}

	openFilters() {
		this.showFilter = !this.showFilter;
	}

	onDateChange(datetime) {
		setTimeout(() => {
			datetime.cancel(true);
		}, 300)
	}

	search() {
		this.filterparams = {
			page: this.pageno,
			size: this.pagesize,
		};
		if (this.searchText && this.searchText.trim() === '') {
			this.searchText = null;
			return;
		}
		if (this.searchText) {
			this.filterparams.search = this.searchText.trim();
			this.getTransactionList(this.filterparams);
		}
	}

	onChange(event): void {
		this.pageno = event;
		this.getTransactionList(this.filterparams);
	}

	applyBtn() {
		if (!this.filterparams) {
			this.filterparams = {};
		}
		if (
			(this.startDate && !this.endDate) ||
			(!this.startDate && this.endDate)
		) {
			if (!this.startDate) {
				this.toastService.displayToast(
					this.translate.instant('error.selectFromDate'),
					null,
					'danger'
				);
			}
			if (!this.endDate) {
				this.toastService.displayToast(
					this.translate.instant('error.selectToDate'),
					null,
					'danger'
				);
			}
			return;
		}
		if (this.startDate && this.endDate) {
			this.filterparams.from_date = this.datePipe.transform(
				this.startDate,
				'yyyy-MM-dd'
			);
			this.filterparams.to_date = this.datePipe.transform(
				this.endDate,
				'yyyy-MM-dd'
			);
		}

		if (this.statusFilter !== 'all') {
			this.filterparams.shipping_status = this.statusFilter;
		} else {
			delete this.filterparams.shipping_status;
			this.pageno = 1;
			this.getTransactionList()
		}
		const keys = Object.keys(this.filterparams);
		if (keys && keys.length) {
			this.pageno = 1;
			this.getTransactionList(this.filterparams);
		}
	}

	clearFilter() {
		delete this.filterparams.from_date;
		delete this.filterparams.to_date;
		delete this.filterparams.shipping_status;
		this.statusFilter = 'all';
		this.dateRange.reset();
		this.startDate = null;
		this.endDate = null;
		this.pageno = 1;
		this.getTransactionList();
	}

	onSort(event, key: any) {
		this.key = key;
		const element = document.querySelectorAll('.sorting-icon');
		element.forEach((img) => {
			img.setAttribute('src', 'assets/icon/sort.svg');
		});

		let eventValue;
		if (event.target.className.includes('column-heading')) {
			eventValue = event.target.children[0];
		} else {
			eventValue = event.srcElement;
		}
		let order = eventValue.getAttribute('data-order');
		if (this.filterparams['sort_by'] != key) {
			order = 'asc';
		}
		if (order === 'asc') {
			this.sortType = 'ASC';
			eventValue.setAttribute('data-order', 'desc');
			eventValue.src = 'assets/icon/sorting_asc.svg';
		} else if (order === 'desc') {
			this.sortType = 'DESC';
			eventValue.setAttribute('data-order', '');
			eventValue.src = 'assets/icon/sorting_desc.svg';
		} else {
			delete this.filterparams['sort_by'];
			delete this.filterparams['sort_type'];
			eventValue.setAttribute('data-order', 'asc');
			eventValue.src = 'assets/icon/sort.svg';
		}
		if (order) {
			this.filterparams['sort_by'] = key;
			this.filterparams['sort_type'] = this.sortType;
			this.pageno = 1;
		}
		this.getTransactionList(this.filterparams);
	}

	clearSearch() {
		this.searchText = null;
		delete this.filterparams.search;
		this.pageno = 1;
		this.getTransactionList(this.filterparams);
	}

	getTransactionList(filterParam?, download?) {
		let params: any = {
			page: this.pageno,
			size: +this.pagesize,
		};
		if (filterParam) {
			params = {
				...params,
				...filterParam,
			};
		}
		if (download) {
			params = {
				dropdown: true
			};
		}
		if (this.isLoading) {
			return;
		}
		if (!download) {
			this.isLoading = true;
			this.transactionList = [];
		}
		this.subscription.add(
			this.dataService.get(URLS.transactionList, params).subscribe(
				(data) => {
					if (data && data.code === 200) {
						if (data.data) {
							if (download) {
								const list =
									data.data.result && data.data.result.length
										? data.data.result
										: [];
								if (list.length) {
									this.exportService.saveAsXlsx(list, 'transaction_list');
								}
							} else {
								this.transactionList =
									data.data.result && data.data.result.length
										? data.data.result
										: [];
								this.totalCount = data.data.total_count;
							}
						}
					} else {
						this.totalCount = 0;
						this.toastService.displayToast(data.message, null, 'danger');
					}
					this.isLoading = false;
					this.isDownloading = false;
				},
				(error) => {
					this.totalCount = 0;
					this.toastService.displayToast(error.message, null, 'danger');
					this.isDownloading = false;
					this.isLoading = false;
				}
			)
		);
	}

	getStatus(status, forLabel?) {
		if (forLabel) {
			switch (status) {
				case 'NOT_YET_DELIVERED':
					return 'notYetDelivered';
				case 'DISPATCHED':
					return 'dispatched';
				case 'DELIVERED':
					return 'delivered';
				case 'CANCELLED':
					return 'canceled';
				case 'CANCELLATION_REQUESTED':
					return 'cancellationRequested';
				case 'RETURNED':
					return 'returned';
				case 'RETURN_REQUESTED':
					return 'returnRequested';
				default:
					return '-'
			}
		} else {
			switch (status) {
				case 'NOT_YET_DELIVERED':
					return 'btn-grey';
				case 'DISPATCHED':
					return 'btn-green';
				case 'DELIVERED':
					return 'btn-black';
				case 'CANCELLATION_REQUESTED':
				case 'RETURN_REQUESTED':
					return 'btn-orange';
				case 'CANCELLED':
				case 'RETURNED':
					return 'btn-red';
				default:
					return 'btn-blue';
			}

		}
	}

	onPageSizeChange(event) {
		this.getTransactionList(this.filterparams);
	}

	downloadExcel() {
		if (this.isDownloading) {
			return;
		}
		this.isDownloading = true;
		this.getTransactionList(null, true);
	}
}
