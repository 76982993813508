import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NgSelectConfig } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { FileSystemDirectoryEntry, FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { Subscription } from 'rxjs';
import { URLS } from '../../_config/api.config';
import { ApiService } from '../../_services/api-data.service';
import { CommonService } from '../../_services/common.service';
import { ModalService } from '../../_services/modal.service';
import { ToastService } from '../../_services/toast.service';

@Component({
	selector: 'app-mass-upload',
	templateUrl: './mass-upload.component.html',
	styleUrls: ['./mass-upload.component.scss'],
})
export class MassUploadComponent implements OnInit {
	subclassList = []; // Tree structure dropdown list array - Sub class
	public files: NgxFileDropEntry[] = [];
	selectedSubclass: any;
	fileSize: any;
	filePath: any;
	percentDone: any = "";
	isLoading = false;
	progress: number;
	public subscription = new Subscription();
	selectedFile: any;
	notFoundText: string;
	constructor(
		private modalService: ModalService,
		private dataService: ApiService,
		private toastService: ToastService,
		private modalCtrl: ModalController,
		public commonService: CommonService,
		private config: NgSelectConfig,
		private translate: TranslateService,

		private http: HttpClient) {
		this.notFoundText = this.translate.instant('noItemFound');
		this.config.notFoundText = this.notFoundText;
	}
	ngOnInit() {
		this.getSubclasses();
	}

	closeModal() {
		if (this.isLoading) {
			return;
		}
		this.modalService.dismissModal();
	}

	create() {
		if (!this.selectedSubclass || !this.selectedFile || this.isLoading) {
			if (!this.selectedSubclass) {
				this.toastService.displayToast('error.subclass', null, 'danger');
				return
			}
			if (!this.selectedFile) {
				this.toastService.displayToast('error.uploadExcel', null, 'danger');
				return
			}
			return
		}
		const params = new FormData();
		params.append('sub_class_id', this.selectedSubclass.replace(/subclass/g, ''));
		params.append('productFile', this.selectedFile)
		this.isLoading = true;
		this.subscription.add(
			this.dataService.post(URLS.bulkUpload, params).subscribe(data => {
				if (data['code'] && data['code'] == 200) {
					this.files = [];
					this.selectedFile = null;
					this.isLoading = false;
					this.modalCtrl.dismiss(true);
				} else {
					this.toastService.displayToast(data['message'], null, 'danger');
					this.isLoading = false;
				}
			}, error => {
				this.isLoading = false;
			})
		)
	}

	public dropped(event) {
		this.progress = 1;
		const formData = new FormData();

		this.files = [event[0]];
		let fileTypes = ['csv', 'text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']
		for (const droppedFile of this.files) {
			// Is it a file?
			if (droppedFile.fileEntry.isFile && droppedFile['isprogress'] != 100) {
				const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
				const reader = new FileReader();
				fileEntry.file((file: File) => {
					// Here you can access the real file
					if (!fileTypes.includes(file.type)) {
						this.toastService.displayToast('error.validExcel', null, 'danger')
						this.selectedFile = null;
						this.files = [];
						return
					}
					this.selectedFile = file;
					formData.append('file', file);
					droppedFile['percentDone'] = "0%";
					this.http
						.post("https://jsonplaceholder.typicode.com/posts", formData, {
							reportProgress: true,
							observe: "events"
						})
						.subscribe((response: any) => {
							if (response.type === HttpEventType["UploadProgress"]) {
								const percentDone = Math.round(
									(100 * response.loaded) / response.total
								);
								droppedFile['percentDone'] = percentDone;
								if (droppedFile['percentDone'] === 100) {
									droppedFile['percentDone'] = droppedFile['percentDone'] + "%";
									setTimeout(() => {
										droppedFile['percentDone'] = "completed";
									}, 0);
									setTimeout(() => {
										droppedFile['percentDone'] = "";
									}, 2000);
								} else {
									droppedFile['percentDone'] = droppedFile['percentDone'] + "%";
								}
								droppedFile['isprogress'] = percentDone;
							} else if (event instanceof HttpResponse) {
								console.log("File is completely uploaded!");
							}
						});

					droppedFile['fileSize'] = (file.size / 1048576).toFixed(2) + " MB";

					reader.readAsDataURL(file);
					reader.onload = () => {
						droppedFile['filePath'] = reader.result;
					};

				});
			} else {
				// It was a directory (empty directories are added, otherwise only files)
				const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
			}
		}
	}

	removeFile(index) {
		this.files.splice(index, 1);
		this.selectedFile = null;
	}

	public fileOver(event) {

	}

	public fileLeave(event) {
	}

	getSubclasses() {
		const params = {
			page: 0,
			limit: 0,
			status: true,
			dropdown: true,
		};
		this.subscription.add(
			this.dataService.get(URLS.consolidatedCategories, params).subscribe(
				(data) => {
					if (data['code'] == 200) {
						if (data['data'] && data['data'].length) {
							let list = data['data'][0]['list'];
							this.subclassList = [];
							this.subClassModelStructure(list);
						}
					} else {
						if (data['code'] != 204) {
							this.toastService.displayToast(data['message'], null, 'danger');
						}
					}
				},
				(error) => {
					this.toastService.displayToast(error['message'], null, 'danger');
				}
			)
		);
	}

	subClassModelStructure(list) {
		list.forEach((group) => {
			let groupObj = {
				name: this.commonService.domDirectionLTR ? group.title : group.title_ar,
				type: group.type,
				level: group.level,
				id: group.id,
				children: group.children,
			};
			if (group.children && group.children.length) {
				groupObj['children'] = group.children;
				let groupChildren = [];
				group.children.forEach((category) => {
					let categoryObj = {};
					categoryObj['name'] = this.commonService.domDirectionLTR ? category.title : category.title_ar;
					categoryObj['type'] = category.type;
					categoryObj['level'] = category.level;
					categoryObj['id'] = category.id;
					categoryObj['children'] = category.children;
					let categoryChildren = [];
					if (category.children && category.children.length) {
						category.children.forEach((classes) => {
							let classObj = {};
							classObj['name'] = this.commonService.domDirectionLTR ? classes.title : classes.title_ar;
							classObj['type'] = classes.type;
							classObj['level'] = classes.level;
							classObj['id'] = classes.id;
							let subclasses = [];
							if (classes.children && classes.children.length) {
								classes.children.forEach((subclass) => {
									let subclassObj = {};
									subclassObj['name'] = this.commonService.domDirectionLTR ? subclass.title : subclass.title_ar;
									subclassObj['type'] = subclass.type;
									subclassObj['level'] = subclass.level;
									subclassObj['id'] = 'subclass' + subclass.id;
									subclasses.push(subclassObj);
								});
							}
							classObj['children'] =
								subclasses && subclasses.length ? subclasses : [];
							if (classObj['children'] && classObj['children'].length) {
								categoryChildren.push(classObj);
							}
						});
					}
					categoryObj['children'] =
						categoryChildren && categoryChildren.length ? categoryChildren : [];
					if (categoryObj['children'] && categoryObj['children'].length) {
						groupChildren.push(categoryObj);
					}
				});
				groupObj['children'] =
					groupChildren && groupChildren.length ? groupChildren : [];
			}
			if (groupObj['children'] && groupObj['children'].length) {
				this.subclassList.push(groupObj);
			}
		});
	}

	subclassChanged(event) {
		let subclass = event.replace(/subclass/g, '');
		this.selectedSubclass = event;
	}

	openFileSelector() {
		this.toastService.displayToast("Please select sub class first.", null, 'danger');
	}

}
