import { DOCUMENT, DatePipe } from '@angular/common';
import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { URLS } from '../../_config/api.config';
import { ApiService } from '../../_services/api-data.service';
import { CommonService } from '../../_services/common.service';
import { ToastService } from '../../_services/toast.service';
import { DetailsComponent } from '../details/details.component';

@Component({
	selector: 'app-brand-management',
	templateUrl: './brand-management.component.html',
	styleUrls: ['./brand-management.component.scss'],
})
export class BrandManagementComponent implements OnInit {
	currencySymbol: string = "$";
	fromDate: any;
	toDate: any;

	showSearch = false;
	showFilter = false;
	pageno = 1;
	pagesize = 10;
	filterparams: any = {};
	searchText: string;
	totalbrandCount = 5;
	perPageInterface = { cssClass: 'custom-select-dd per-page-dd' };
	selectInterface = { cssClass: 'custom-select-dd' };
	public posts = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25];

	public count = 0;

	public itemsPerPage = 10;
	public currentPage = 1;
	private subscription: Subscription = new Subscription();
	showLoader: boolean = false;
	totalBrandCount: number = 0;
	brandListData: any[] = [];
	page = 1;
	pageSize = 10;
	columnHeaders = {
		order_id: 'Order #',
		date: 'Date',
		ship_to: 'Ship to',
		order_total: 'Order total',
		status: 'Status',
	}

	dateRange: FormGroup;
	maxFromDate: any;
	maxDate: any;
	minToDate: any;
	todaysDate = new Date();
	searchObj: any = {};
	status = null;
	key: string = null;
	sortType: string = null;
	statusList = [];
	// @Input() showHeader: boolean;
	constructor(
		private modalCtrl: ModalController,
		private alertController: AlertController,
		private targetElem: ElementRef,
		public commonService: CommonService,
		private fb: FormBuilder,
		private datePipe: DatePipe,
		private toastService: ToastService,
		private dataService: ApiService,
		private translate: TranslateService,
		@Inject(DOCUMENT) private document: Document,
	) { }

	// add or edit brand details
	async details(data?) {
		//this.initializeDateForm();
		const modal = await this.modalCtrl.create({
			component: DetailsComponent,
			cssClass: 'details-modal',
			backdropDismiss: false,
			componentProps: { brandData: data ? data : null }
		});
		modal.onDidDismiss().then(data => {
			if (data['data'] == 'isSuccess') {
				this.getBrandList(null);
			}
		})
		modal.present();
	}

	changeIsActive(id, brand) {
		const params = new FormData();
		params.append('brand_id', id);
		params.append('is_active', brand.is_active);
		this.subscription.add(
			this.dataService.put(URLS.updateBrand, params).subscribe(data => {
				if (data.code != 200) {
					brand.is_active = !brand.is_active;
					this.toastService.displayToast(data['message'], null, 'danger');
				}
			}, error => {
				brand.is_active = !brand.is_active;
				this.toastService.displayToast(error['message'], null, 'danger');
			})
		)
	}

	ngOnInit() {
		const element = this.document.querySelector('#scrollId');
		if (element) {
			element.scrollIntoView();
		}
		this.searchText = null;
		this.getBrandList()
		this.initializeDateForm()
		let date = new Date();
		this.maxFromDate = date.setDate(this.todaysDate.getDate());
		this.maxDate = this.datePipe.transform(this.todaysDate, 'YYYY-MM-dd');
		this.maxFromDate = this.datePipe.transform(this.maxFromDate, 'YYYY-MM-dd');
		this.statusList = [
			{ value: '', label: this.commonService.domDirectionLTR ? 'All' : 'الجميع' },
			{ value: 'APPROVED', label: this.commonService.domDirectionLTR ? 'Approved' : 'وافق' },
			{ value: 'PENDING', label: this.commonService.domDirectionLTR ? 'Pending' : 'قيد الانتظار' },
			{ value: 'REJECTED', label: this.commonService.domDirectionLTR ? 'Rejected' : 'مرفوض' }
		]
		// this.orderList.forEach(element => {
		// 	element['is_main_brand'] = false;
		// });

	}

	getBrandList(searchData?) {
		this.showLoader = true;
		let params = {
			page: this.page,
			limit: this.pageSize
		}
		if (searchData) {
			params = {
				...params,
				...searchData
			}

		}
		if (this.filterparams['sort_by']) {
			params['sort_by'] = this.filterparams['sort_by'];
			params['sort_type'] = this.sortType;
		}

		this.subscription.add(
			this.dataService.get(URLS.brandList, params).subscribe(data => {
				this.showLoader = false;
				const element = this.document.querySelector('#scrollId');
				if (element) {
					element.scrollIntoView();
				}
				if (data['code'] == 200) {
					this.totalBrandCount = parseInt(data['data']['total_count'])
					this.brandListData = [];
					if (data['data'] && data['data']['result'].length) {
						let list = data['data'] && data['data']['result'].length ? data['data']['result'] : [];
						list.forEach((brand) => {
							let obj = {
								brand_id: brand.brand_id ? brand.brand_id : '',
								brand_name: this.commonService.domDirectionLTR ? brand.brand_name_en ? brand.brand_name_en : '-' : brand.brand_name_ar ? brand.brand_name_ar : '-',
								brand_logo: brand.brand_logo_url,
								approval_status: brand.approval_status,
								is_main_brand: brand.is_main_brand,
								is_active: brand.is_active,
							};
							this.brandListData.push(obj);
						});
					}
					this.showLoader = false;
				} else {
					this.showLoader = false;
					this.toastService.displayToast(data['message'], null, 'danger');
				}
			}, error => {
				this.showLoader = false;
				this.brandListData = [];
				this.toastService.displayToast(error['message'], null, 'danger');
			})
		)
	}

	//useful code
	//on change of main brand

	changeMainBrand(index, id, brand) {
		// this.orderList.forEach(element => {
		// 		element['is_main_brand'] = false;
		// })
		// this.orderList[index]['is_main_brand'] = true;
		const params = new FormData();
		params.append('brand_id', id);
		params.append('is_main_brand', brand.is_main_brand);
		this.subscription.add(
			this.dataService.put(URLS.updateBrand, params).subscribe(data => {
				if (data.code == 200) {
					this.getBrandList()
				}
				else {
					brand.is_main_brand = !brand.is_main_brand
					this.toastService.displayToast(data['message'], null, 'danger');
				}
			}, error => {
				brand.is_main_brand = !brand.is_main_brand
				this.toastService.displayToast(error['message'], null, 'danger');
			})
		)
	}


	initializeDateForm() {
		this.dateRange = this.fb.group({
			fromDate: new FormControl(null),
			toDate: new FormControl(null),
		})
	}

	clearSearch() {
		let searchData = {}
		this.searchText = null;
		searchData['search'] = this.searchText;
		this.getBrandList();
	}

	// search text parameter in api
	search() {
		let searchObj = {}
		if (this.searchText && this.searchText.trim() == '') {
			this.searchText = null;
			return
		}
		if (this.searchText) {
			searchObj['search'] = this.searchText.trim();
			this.page = 1;
			this.currentPage = 1
			this.getBrandList(searchObj);
		}
	}

	openFilters() {
		this.showFilter = !this.showFilter;
	}

	onChange(event): void {
		this.currentPage = event;
		this.page = this.currentPage;
		this.getBrandList()
	}

	onDateChange(datetime) {
		setTimeout(() => {
			datetime.cancel(true);
		}, 300)
	}

	clearFilter() {
		this.fromDate = null;
		this.toDate = null;
		this.status = null;
		this.searchText = null
		this.searchObj = null;
		this.dateRange.reset();
		this.getBrandList(null)

	}

	selectPageLength(event) {
		let selectPageSize = event.detail.value;
		this.itemsPerPage = event.detail.value
		this.currentPage = 1;
		this.page = 1;
		this.pageSize = selectPageSize;
		this.getBrandList();
	}

	applyBtn() {
		this.searchObj = {}
		if (this.searchText) {
			this.searchObj['search'] = this.searchText.trim();
		}
		if (this.status) {
			this.searchObj['approval_status'] = this.status;
		}
		if ((this.fromDate && !this.toDate) || (!this.fromDate && this.toDate)) {
			if (!this.fromDate) {
				this.toastService.displayToast(this.translate.instant('error.selectFromDate'), null, 'danger');
			}
			if (!this.toDate) {
				this.toastService.displayToast(this.translate.instant('error.selectToDate'), null, 'danger');
			}
			return
		}
		if (this.fromDate && this.toDate) {
			this.searchObj['from_date'] = this.datePipe.transform(this.fromDate, 'yyyy-MM-dd');
			this.searchObj['to_date'] = this.datePipe.transform(this.toDate, 'yyyy-MM-dd');
		}
		// if (this.searchObj['from_date'] == this.searchObj['to_date']) {
		// 	this.toastService.displayToast("'From Date' and 'To Date' cannot be same.", null, 'danger');
		// 	return
		// }
		this.getBrandList(this.searchObj)
	}

	onSort(event, key: any) {
		if (!this.brandListData.length) {
			return;
		}
		this.key = key;

		const element = document.querySelectorAll('.sorting-icon');
		element.forEach(img => {
			img.setAttribute("src", "assets/icon/sort.svg")
		});

		let eventValue;
		if (event.target.className.includes("column-heading")) {
			eventValue = event.target.children[0];
		} else {
			eventValue = event.srcElement;
		}
		let order = eventValue.getAttribute("data-order");
		if (this.filterparams && this.filterparams['sort_by'] && this.filterparams['sort_by'] != key) {
			order = "asc";
		}
		if (order === "asc") {
			this.sortType = "ASC";
			eventValue.setAttribute("data-order", "desc");
			eventValue.src = "assets/icon/sorting_asc.svg";
		} else if (order === "desc") {
			this.sortType = "DESC";
			eventValue.setAttribute("data-order", "");
			eventValue.src = "assets/icon/sorting_desc.svg";
		} else {
			delete this.filterparams['sort_by'];
			delete this.filterparams['sort_type'];
			eventValue.setAttribute("data-order", "asc");
			eventValue.src = "assets/icon/sort.svg";
		}
		if (order) {
			this.filterparams['sort_by'] = key;
			this.filterparams['sort_type'] = this.sortType;
		}
		this.pageno = 1;
		this.getBrandList();
	}

}
