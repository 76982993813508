import { Component, OnInit } from '@angular/core';
import { NgSelectConfig } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { URLS } from 'src/app/_config/api.config';
import { ApiService } from 'src/app/_services/api-data.service';
import { ExportExcelService } from '../../_services/export-excel.service';
import { CommonService } from './../../_services/common.service';
import { ModalService } from './../../_services/modal.service';
import { ToastService } from './../../_services/toast.service';

@Component({
	selector: 'app-template-download',
	templateUrl: './template-download.component.html',
	styleUrls: ['./template-download.component.scss'],
})
export class TemplateDownloadComponent implements OnInit {
	subclassList = []; // Tree structure dropdown list array - Sub class
	selectedSubclass: any;
	templateHeader = []
	onlySubclass = [];
	public subscription = new Subscription();
	fetchingData = false;
	allowDownload = false;
	templateData: any;
	notFoundText: string;
	constructor(
		private modalService: ModalService,
		private dataService: ApiService,
		private toastService: ToastService,
		public commonService: CommonService,
		private exportExcel: ExportExcelService,
		private config: NgSelectConfig,
		private translate: TranslateService,

	) {
		this.notFoundText = this.translate.instant('noItemFound');
		this.config.notFoundText = this.notFoundText;
	}

	ngOnInit() {
		this.getSubclasses();
	}

	closeModal() {
		this.modalService.dismissModal();
	}

	getSubclasses() {
		const params = {
			page: 0,
			limit: 0,
			status: true,
			dropdown: true,
		};
		this.subscription.add(
			this.dataService.get(URLS.consolidatedCategories, params).subscribe(
				(data) => {
					if (data['code'] == 200) {
						if (data['data'] && data['data'].length) {
							let list = data['data'][0]['list'];
							this.subclassList = [];
							this.onlySubclass = [];
							this.subClassModelStructure(list);
						}
					} else {
						if (data['code'] != 204) {
							this.toastService.displayToast(data['message'], null, 'danger');
						}
					}
				},
				(error) => {
					this.toastService.displayToast(error['message'], null, 'danger');
				}
			)
		);
	}

	subClassModelStructure(list) {
		list.forEach((group) => {
			let groupObj = {
				name: this.commonService.domDirectionLTR ? group.title : group.title_ar,
				type: group.type,
				level: group.level,
				id: group.id,
				children: group.children,
			};
			if (group.children && group.children.length) {
				groupObj['children'] = group.children;
				let groupChildren = [];
				group.children.forEach((category) => {
					let categoryObj = {};
					categoryObj['name'] = this.commonService.domDirectionLTR ? category.title : category.title_ar;
					categoryObj['type'] = category.type;
					categoryObj['level'] = category.level;
					categoryObj['id'] = category.id;
					categoryObj['children'] = category.children;
					let categoryChildren = [];
					if (category.children && category.children.length) {
						category.children.forEach((classes) => {
							let classObj = {};
							classObj['name'] = this.commonService.domDirectionLTR ? classes.title : classes.title_ar;
							classObj['type'] = classes.type;
							classObj['level'] = classes.level;
							classObj['id'] = classes.id;
							let subclasses = [];
							if (classes.children && classes.children.length) {
								classes.children.forEach((subclass) => {
									let subclassObj = {};
									subclassObj['name'] = this.commonService.domDirectionLTR ? subclass.title : subclass.title_ar;
									subclassObj['type'] = subclass.type;
									subclassObj['level'] = subclass.level;
									subclassObj['id'] = 'subclass' + subclass.id;
									subclasses.push(subclassObj);
									this.onlySubclass.push(subclassObj);
								});
							}
							classObj['children'] =
								subclasses && subclasses.length ? subclasses : [];
							if (classObj['children'] && classObj['children'].length) {
								categoryChildren.push(classObj);
							}
						});
					}
					categoryObj['children'] =
						categoryChildren && categoryChildren.length ? categoryChildren : [];
					if (categoryObj['children'] && categoryObj['children'].length) {
						groupChildren.push(categoryObj);
					}
				});
				groupObj['children'] =
					groupChildren && groupChildren.length ? groupChildren : [];
			}
			if (groupObj['children'] && groupObj['children'].length) {
				this.subclassList.push(groupObj);
			}
		});
	}

	subclassChanged(event) {
		this.selectedSubclass = event;
		this.allowDownload = false;
		this.getTemplate(this.selectedSubclass.replace(/subclass/g, ''))
	}


	getTemplate(id) {
		if (this.fetchingData) {
			return
		}
		const params = {
			"sub_class_id": +id
		}
		this.fetchingData = true;
		this.allowDownload = false;
		this.subscription.add(
			this.dataService.post(URLS.generateTemplate, params).subscribe(data => {
				if (data['code'] == 200) {
					this.allowDownload = true;
					this.templateHeader = data['data'] && data['data'].length ? data['data'] : [];
				} else {
					this.toastService.displayToast(data['message'], null, 'danger');
				}
				this.fetchingData = false
			}, error => {
				this.fetchingData = false
			})
		);
	}

	downloadTemplate() {
		if (this.templateHeader && this.templateHeader.length) {
			let obj = this.onlySubclass.find(el => el.id == this.selectedSubclass)
			if (obj) {
				this.exportExcel.csvFile(this.templateHeader, obj.name)
			}

		}
	}

}
