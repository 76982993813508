import { DOCUMENT, DatePipe } from '@angular/common';
import { Component, ElementRef, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ExportExcelService } from 'src/app/_services/export-excel.service';
import { URLS } from '../../_config/api.config';
import { ApiService } from '../../_services/api-data.service';
import { CommonService } from '../../_services/common.service';
import { ModalService } from '../../_services/modal.service';
import { ToastService } from '../../_services/toast.service';
import { AddFeaturedProductComponent } from '../add-featured-product/add-featured-product.component';
import { ProductPreviewDetailComponent } from '../product-preview-detail/product-preview-detail.component';

@Component({
	selector: 'app-featured-product-management',
	templateUrl: './featured-product-management.component.html',
	styleUrls: ['./featured-product-management.component.scss'],
})
export class FeaturedProductManagementComponent implements OnInit {
	currencySymbol: string = "$";
	showSearch = false;
	showFilter = false;
	fromDate: any;
	toDate: any;
	statusSelect: any;
	statusList = [];
	isLoading: boolean = false;
	searchText: any;
	showLoader: boolean = false;
	pageno = 1;
	pagesize = 10;
	filterparams: any;
	public subscription = new Subscription();
	searchObj: any = {};
	perPageInterface = { cssClass: 'custom-select-dd per-page-dd' };
	selectInterface = { cssClass: 'custom-select-dd' };
	totalFeaturedCount = 0;
	public posts = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25];

	public count = 0;

	public itemsPerPage = 10;

	public currentPage = 1;

	columnHeaders = {
		order_id: 'Order #',
		date: 'Date',
		ship_to: 'Ship to',
		order_total: 'Order total',
		status: 'Status',

	}
	featuredProductList = [];
	statusFilter = 'all';
	maxFromDate: any;
	minToDate: any;
	todaysDate = new Date();
	maxDate: any;
	@Input() showHeader: boolean;
	dateRange: FormGroup;
	isDownloading: boolean = false;

	constructor(
		private modalService: ModalService,
		public commonService: CommonService,
		private alertController: AlertController,
		private fb: FormBuilder,
		private datePipe: DatePipe,
		private modalCtrl: ModalController,
		private toastService: ToastService,
		private targetElem: ElementRef,
		private router: Router,
		@Inject(DOCUMENT) private document: Document,
		private dataService: ApiService,
		private translate: TranslateService,
		public exportService: ExportExcelService
	) { }

	ngOnInit() {
		this.statusList = [
			{ value: 'all', label: this.commonService.domDirectionLTR ? 'All' : 'الجميع' },
			{ value: 'APPROVED', label: this.commonService.domDirectionLTR ? 'Approved' : 'وافق' },
			{ value: 'PENDING', label: this.commonService.domDirectionLTR ? 'Pending' : 'قيد الانتظار' },
			{ value: 'REJECTED', label: this.commonService.domDirectionLTR ? 'Rejected' : 'مرفوض' },
			{ value: 'EXPIRED', label: this.commonService.domDirectionLTR ? 'Expired' : 'منتهي الصلاحية' },
		]
		const element = this.document.querySelector('#scrollId');
		if (element) {
			element.scrollIntoView();
		}
		this.dateRange = this.fb.group({
			fromDate: new FormControl(null),
			toDate: new FormControl(null),
		})
		let date = new Date();
		this.maxFromDate = date.setDate(this.todaysDate.getDate() - 1);
		this.maxDate = this.datePipe.transform(this.todaysDate, 'YYYY-MM-dd');
		this.maxFromDate = this.datePipe.transform(this.maxFromDate, 'YYYY-MM-dd');

		this.searchText = null;
		this.filterparams = {
			page: this.pageno,
			size: this.pagesize
		}
		this.getFeaturedList(this.filterparams);
	}

	ionViewWillEnter() {
		this.showHeader = true;
	}

	openFilters() {
		this.showFilter = !this.showFilter;
	}

	onChange(event): void {
		this.currentPage = event;
		this.pageno = event;
		let params = {
			page: event,
			size: this.pagesize
		}
		this.getFeaturedList(params, false);
	}

	// select data per page
	selectPageLength(event) {
		let selectPageSize = event.detail.value;
		this.itemsPerPage = event.detail.value
		this.pagesize = selectPageSize;
		this.filterparams['page'] = 1;
		this.filterparams['size'] = selectPageSize;
		this.getFeaturedList(this.filterparams, false)
	}

	async presentAlert(productId) {
		const alert = await this.alertController.create({
			header: 'Are you sure?',
			message: 'You want to edit product?',
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
					handler: () => {
					},
				},
				{
					text: 'OK',
					role: 'confirm',
					handler: () => {

					},
				},
			],
		});

		await alert.present();

		const { role } = await alert.onDidDismiss();

	}

	async editProductDetails(product: any) {
		const modal = await this.modalCtrl.create({
			component: AddFeaturedProductComponent,
			cssClass: 'edit-featured-product-modal',
			componentProps: {
				isEdit: true,
				editData: product
			}
		});
		modal.present();
		modal.onDidDismiss().then((data) => {
			if (data && data.data) {
				this.filterparams = {
					page: this.pageno,
					size: this.pagesize
				}
				this.getFeaturedList(this.filterparams);
			}
		})
	}

	async addProducts(data?) {
		const modal = await this.modalCtrl.create({
			component: AddFeaturedProductComponent,
			cssClass: 'product-discount-modal',
			componentProps: { brandData: data ? data : '' }
		});
		modal.present();
		modal.onDidDismiss().then((data) => {
			if (data && data.data) {
				this.pageno = 1;
				this.currentPage = 1;
				this.filterparams = {
					page: this.pageno,
					size: this.pagesize
				}
				this.getFeaturedList(this.filterparams);
			}
		})
	}

	onDateChange(datetime) {
		setTimeout(() => {
			datetime.cancel(true);
		}, 300)
	}

	key: string = "id";
	sortType: string = "ASC";
	onSort(event, key: any) {
		if (!this.featuredProductList.length) {
			return;
		}
		this.key = key;
		const element = document.querySelectorAll('.sorting-icon');
		element.forEach(img => {
			img.setAttribute("src", "assets/icon/sort.svg")
		});

		let eventValue;
		if (event.target.className.includes("column-heading")) {
			eventValue = event.target.children[0];
		} else {
			eventValue = event.srcElement;
		}
		let order = eventValue.getAttribute("data-order")
		if (this.searchObj['sort_by'] != key) {
			order = "asc"
		}
		if (order === "asc") {
			this.sortType = "ASC";
			eventValue.setAttribute("data-order", "desc");
			eventValue.src = "assets/icon/sorting_asc.svg";
		} else if (order === "desc") {
			this.sortType = "DESC";
			eventValue.setAttribute("data-order", "");
			eventValue.src = "assets/icon/sorting_desc.svg";
		} else {
			delete this.searchObj['sort_by'];
			delete this.searchObj['sort_type'];
			eventValue.setAttribute("data-order", "asc");
			eventValue.src = "assets/icon/sort.svg";
		}
		if (order) {
			this.searchObj['sort_by'] = key;
			this.searchObj['sort_type'] = this.sortType;
		}
		this.searchObj['page'] = 1;
		this.searchObj['size'] = this.pagesize;
		this.getFeaturedList(this.searchObj, false);
	}

	getFeaturedList(params?, clearFilter?: boolean) {

		if (this.searchObj) {
			params = { ...this.searchObj, ...params }
		}
		if (clearFilter) {
			params = {
				'page': 1,
				'size': this.pagesize
			}
		}
		if (this.isDownloading) {
			params['dropdown'] = true;
		}
		this.showLoader = true;
		this.subscription.add(
			this.dataService.get(URLS.featuredList, params).subscribe(data => {
				if (data && data['code'] == 200) {
					this.totalFeaturedCount = data['data'] && data['data']['total_count'] ? +data['data']['total_count'] : 0
					this.featuredProductList = [];
					if (data['data'] && data['data']['result'] && data['data']['result'].length) {
						let list = data['data']['result'];
						list.forEach(element => {
							let obj = {
								request_id: element.request_id,
								product_id: element.product_id,
								web_image_url: element.web_image_url ? element.web_image_url : '',
								mobile_image_url: element.mobile_image_url ? element.mobile_image_url : '',
								name: this.commonService.domDirectionLTR ? element.product_name_en ? element.product_name_en : '' : element.product_name_ar ? element.product_name_ar : '',
								subclass: this.commonService.domDirectionLTR ? element.display_sub_class_name_en ? element.display_sub_class_name_en : '' : element.sub_class_name_ar ? element.sub_class_name_ar : '',
								start_date: element.start_date ? element.start_date : '',
								end_date: element.end_date ? element.end_date : '',
								commission_percent: element.commission_request_percentage ? element.commission_request_percentage : '-',
								commission_value: element.commission_request_value ? element.commission_request_value : 0,
								region: this.commonService.domDirectionLTR ? element.region_en ? element.region_en : '' : element.region_ar ? element.region_ar : '',
								product_status: element.approval_status,
							}
							this.featuredProductList.push(obj)
						});
					} else {
						this.featuredProductList = [];
					}

					if (this.isDownloading) {
						const list = data.data.result && data.data.result.length ? data.data.result : [];
						if (list.length) {
							this.exportService.saveAsXlsx(list, 'feature_product_list');
						}
					}

					this.showLoader = false;
				} else {
					this.showLoader = false;
					if (data['code'] != 204) {
						this.toastService.displayToast(data['message'], null, 'danger');
					}
				}

			}, error => {
				this.showLoader = false;
				this.toastService.displayToast(error['message'], null, 'danger');
			})
		);
	}
	// search text parameter in api
	search() {
		this.filterparams = {
			page: this.pageno,
			size: this.pagesize
		}
		if (this.searchText && this.searchText.trim() == '') {
			this.searchText = null;
			return
		}
		if (this.searchText) {
			this.searchObj['search'] = this.searchText.trim();
			this.getFeaturedList(this.filterparams, false);
		}
	}

	applyBtn() {
		this.searchObj = {}
		this.currentPage = 1;
		this.searchObj['page'] = 1;
		this.searchObj['size'] = this.pagesize;
		let bypassCondition = true;
		if (this.statusFilter != 'all') {
			this.searchObj['approval_status'] = this.statusFilter;
		}
		if ((this.fromDate && !this.toDate) || (!this.fromDate && this.toDate)) {
			if (!this.fromDate) {
				this.toastService.displayToast(this.translate.instant('error.selectFromDate'), null, 'danger');
			}
			if (!this.toDate) {
				this.toastService.displayToast(this.translate.instant('error.selectToDate'), null, 'danger');
			}
			return
		}
		if (this.fromDate && this.toDate) {
			this.searchObj['from_date'] = this.datePipe.transform(this.fromDate, 'yyyy-MM-dd');
			this.searchObj['to_date'] = this.datePipe.transform(this.toDate, 'yyyy-MM-dd');
		}
		if (!bypassCondition && this.searchObj['from_date'] && this.searchObj['to_date'] && (this.searchObj['from_date'] == this.searchObj['to_date'])) {
			this.toastService.displayToast("'From Date' and 'To Date' cannot be same.", null, 'danger');
			return
		}
		this.getFeaturedList(this.searchObj, false)
	}

	clearSearch() {
		this.searchText = null;
		let params = {
			page: 1,
			size: this.pagesize
		}
		delete this.searchObj['search']
		this.getFeaturedList(params, false);
	}


	clearFilter() {
		this.statusFilter = 'all';
		this.fromDate = null;
		this.toDate = null;
		this.searchObj = {};
		this.searchObj['page'] = 1;
		this.searchObj['size'] = this.pagesize;
		setTimeout(() => {
			this.getFeaturedList(this.searchObj, true);
		}, 300);
	}

	preview(id) {
		if (!id) {
			return
		}
		let obj = {
			component: ProductPreviewDetailComponent,
			backdropDismiss: false,
			cssClass: 'preview-modal',
			componentProps: {
				product_id: id
			},
		};
		const modal = this.modalService.openModal(obj);
		modal.then(value => {
		}, error => {
			console.log(error);
		});
	}

	viewProductDetails(product_id: any) {
		this.router.navigate(['/account-details/products-management/view-product', product_id], {
			queryParams: {
				"from": "FeatureProduct"
			}
		})
	}

	downloadExcel() {
		if (this.isDownloading) {
			return;
		}
		this.isDownloading = true;
		this.getFeaturedList();
	}
}
