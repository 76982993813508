import { DatePipe, DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { CommonService } from '../../_services/common.service';
import { URLS } from './../../_config/api.config';
import { ApiService } from './../../_services/api-data.service';
import { ExportExcelService } from './../../_services/export-excel.service';
import { ToastService } from './../../_services/toast.service';

@Component({
	selector: 'app-inventory-management',
	templateUrl: './inventory-management.component.html',
	styleUrls: ['./inventory-management.component.scss'],
})
export class InventoryManagementComponent implements OnInit {
	isModalOpen = false;
	perPageInterface = { cssClass: 'custom-select-dd per-page-dd' }

	public posts = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25];

	public count = 0;

	public itemsPerPage = 10;
	warehouseName: any;
	productName: any;
	totalQuantity: any;
	quantitySold: any;
	searchText: any;
	pageno = 1;
	pagesize = 10;
	filterparams: any;
	key: string = "id";
	sortType: string = "ASC";
	inventoryData: any = [];
	subClassesList: any = [];
	public currentPage = 1;
	public totalCount: number = 0;
	private subscription: Subscription = new Subscription();
	showLoader: boolean = false;
	showFilter: boolean = false;
	searchObj: any = {};
	enableEdit: boolean = false;
	editProductId: any;
	editWarehouseId: any;
	editdata: any;
	isUpdating: boolean = false;
	seqId: any;
	dateRange: FormGroup;
	selectSubClass: any;
	createdFromDate: any;
	createdToDate: any;
	maxFromDate: any;
	minToDate: any;
	todaysDate = new Date();
	maxDate: any;
	constructor(
		public commonService: CommonService,
		private targetElem: ElementRef,
		private fb: FormBuilder,
		private toastService: ToastService,
		private dataService: ApiService,
		private translate: TranslateService,
		private exportExcel: ExportExcelService, private alertController: AlertController,
		@Inject(DOCUMENT) private document: Document,
		private datePipe: DatePipe,
	) { }

	ngOnInit() {
		this.getSubclass();
		let date = new Date();
		this.maxFromDate = date.setDate(this.todaysDate.getDate() - 1);
		this.maxDate = this.datePipe.transform(this.todaysDate, 'YYYY-MM-dd');
		this.maxFromDate = this.datePipe.transform(this.maxFromDate, 'YYYY-MM-dd');
		const element = this.document.querySelector('#scrollId');
		if (element) {
			element.scrollIntoView();
		}
		this.searchText = null;
		this.filterparams = {
			page: this.pageno,
			limit: this.pagesize
		}
		this.dateRange = this.fb.group({
			fromDate: new FormControl(null),
			toDate: new FormControl(null),
		})
		this.getInventoryList();

	}

	onChange(event): void {
		this.currentPage = event;
		this.pageno = event;
		// this.getInventoryList();
	}

	rowClick(data) {
		if (data.sub_product && data.sub_product.length) {
			data.sub_product.forEach((element) => {
				element.expand = !element.expand;
			});
		}
		data.expand = !data.expand;
	}

	openModal(data) {
		this.productName = data.product;
		this.warehouseName = data.warehouse_one_name;
		this.totalQuantity = data.total_qty;
		this.quantitySold = data.qty_sold;
		this.isModalOpen = true;
	}

	closeModal() {
		this.isModalOpen = false;
	}


	async presentAlert(warehouse) {
		if (this.enableEdit) {
			this.toastService.displayToast(this.translate.instant('saveChanges'), null, 'danger')
			return
		}
		warehouse.is_active = !warehouse.is_active;
		let deactivateMsg = this.commonService.domDirectionLTR ? "You want to deactivate warehouse." : "تريد إلغاء تنشيط المستودع.";
		let activateMsg = this.commonService.domDirectionLTR ? "You want to activate warehouse" : "تريد تنشيط المستودع";
		const alert = await this.alertController.create({
			header: this.commonService.domDirectionLTR ? 'Are you sure?' : 'هل أنت متأكد؟',
			message: warehouse.is_active ? deactivateMsg : activateMsg,
			buttons: [
				{
					text: this.commonService.domDirectionLTR ? 'Cancel' : 'يلغي',
					role: 'cancel',
					handler: () => {

					},
				},
				{
					text: this.commonService.domDirectionLTR ? 'Yes' : 'نعم',
					role: 'confirm',
					handler: () => {
						this.updateInventory(warehouse, true)
					},
				},
			],
		});
		await alert.present();
		const { role } = await alert.onDidDismiss();
	}
	// select data per page
	selectPageLength(event) {
		let selectPageSize = event.detail.value;
		this.itemsPerPage = event.detail.value
		this.pagesize = selectPageSize;
		this.currentPage = 1;
		this.filterparams['page'] = 1;
		this.filterparams['limit'] = selectPageSize;
		// this.getInventoryList()
	}

	getInventoryList(filterParams?, clearFilter?: boolean) {
		this.showLoader = true;
		let param = {
			'page': this.currentPage,
			'limit': this.pagesize
		}
		if (clearFilter) {
			param = {
				'page': 1,
				'limit': this.pagesize
			}
		}
		if (filterParams) {
			param = {
				...param,
				...filterParams
			}
		}
		this.subscription.add(
			this.dataService.get(URLS.inventoryList, param).subscribe(data => {
				if (data['code'] == 200) {
					this.inventoryData = [];
					this.totalCount = parseInt(data['data']['total_count'])
					this.inventoryData = data['data'] && data['data']['result'] && data['data']['result'].length ? data['data']['result'] : [];
					this.inventoryData.forEach(element => {
						element['isMainProduct'] = true;
						if (element.sub_product && element.sub_product.length) {
							element.sub_product.forEach(subproduct => {
								subproduct['isSubproduct'] = true;
								if (subproduct['ware_house'] && subproduct['ware_house'].length > 0) {
									subproduct['ware_house'].forEach(ele => {
										ele['isWarehouse'] = true;
										ele['product_id'] = subproduct['product_id'];
										ele['main_product_id'] = element['product_id'];
										ele['seqId'] = element['product_id'] + '' + ele['warehouse_id'];
									});
								}
							});
						}

					});
					this.showLoader = false;
				} else {
					this.showLoader = false;
					this.toastService.displayToast(data['message'], null, 'danger');
				}
			}, error => {
				this.showLoader = false;
				this.toastService.displayToast(error['message'], null, 'danger');
			})
		)
	}
	onSort(event, key: any) {
		this.key = key;

		const element = document.querySelectorAll('.sorting-icon');
		element.forEach(img => {
			img.setAttribute("src", "assets/icon/sort.svg")
		});

		let eventValue;
		if (event.target.className.includes("column-heading")) {
			eventValue = event.target.children[0];
		} else {
			eventValue = event.srcElement;
		}
		let order = eventValue.getAttribute("data-order")
		if (this.filterparams['sort_by'] != key) {
			order = "asc"
		}
		if (order === "asc") {
			this.sortType = "ASC";
			eventValue.setAttribute("data-order", "desc");
			eventValue.src = "assets/icon/sorting_asc.svg";
		} else if (order === "desc") {
			this.sortType = "DESC";
			eventValue.setAttribute("data-order", "");
			eventValue.src = "assets/icon/sorting_desc.svg";
		} else {
			delete this.filterparams['sort_by'];
			delete this.filterparams['sort_type'];
			eventValue.setAttribute("data-order", "asc");
			eventValue.src = "assets/icon/sort.svg";

		}
		if (order) {
			this.filterparams['sort_by'] = key;
			this.filterparams['sort_type'] = this.sortType;
		}
		this.getInventoryList(this.filterparams, false)
	}

	startEdit(data) {
		if (this.enableEdit) {
			this.toastService.displayToast(this.translate.instant('saveChanges'), null, 'danger')
			return
		}
		if (!data.expand) {
			this.rowClick(data);
		}
		this.editProductId = data.product_id;
		this.editWarehouseId = data.warehouse_id;
		this.editdata = JSON.parse(JSON.stringify(data));
		this.enableEdit = true;
		this.seqId = data.seqId;
	}

	cancelEdit(data) {
		if (this.editdata.sub_product && this.editdata.sub_product.length) {
			this.editdata.sub_product.forEach((subproducts, subProductIndex) => {
				if (subproducts.ware_house && subproducts.ware_house.length) {
					subproducts.ware_house.forEach((warehouse, warehouseIndex) => {
						data.sub_product[subProductIndex]['ware_house'][warehouseIndex][
							'total_quantity'
						] = warehouse.total_quantity;
					});
				}
			});
		}
		this.editdata = null;
		this.editProductId = null;
		this.editWarehouseId = null;
		this.enableEdit = false;
		this.seqId = null;
	}


	numberOnlyValidation(event: any) {
		const pattern = /[0-9]/;
		let inputChar = String.fromCharCode(event.charCode);

		if (!pattern.test(inputChar)) {
			// invalid character, prevent input
			event.preventDefault();
		}
	}

	updateInventory(warehouseData, disable?) {
		let params: any;
		if (disable) {
			params = {
				inventory_data: [
					{
						product_id: warehouseData.product_id,
						warehouse_id: warehouseData.warehouse_id,
						available_quantity: +warehouseData.total_quantity,
						is_active: disable
							? !warehouseData.is_active
							: warehouseData.is_active,
					},
				],
			};
		} else {
			let invData = [];
			if (warehouseData.sub_product && warehouseData.sub_product.length) {
				warehouseData.sub_product.forEach(subproducts => {
					if (subproducts.ware_house && subproducts.ware_house.length) {
						subproducts.ware_house.forEach(warehouse => {
							invData.push({
								product_id: warehouse.product_id,
								warehouse_id: warehouse.warehouse_id,
								available_quantity: +warehouse.total_quantity,
								is_active: disable ? !warehouse.is_active : warehouse.is_active,
							});
						});
					}
				});
			}
			params = {
				inventory_data: invData
			};
		}
		if (this.isUpdating) {
			return;
		}
		this.isUpdating = true;
		this.subscription.add(
			this.dataService.post(URLS.updateProductQuantity, params).subscribe(data => {
				if (data['code'] == 200) {
					this.editdata = null;
					this.editProductId = null;
					this.editWarehouseId = null;
					this.enableEdit = false;
					this.seqId = null;
					this.toastService.displayToast(data['message'], null, 'success')
					if (disable) {
						warehouseData.is_active = !warehouseData.is_active;
					} else {
						this.getInventoryList()
					}
				} else {
					this.toastService.displayToast(data['message'], null, 'danger')
				}
				this.isUpdating = false;
			}, error => {
				this.isUpdating = false;
				this.toastService.displayToast(error['message'], null, 'danger')

			})
		)
	}

	openFilters() {
		this.showFilter = !this.showFilter;
	}

	applyBtn() {
		if (this.selectSubClass) {
			this.searchObj['sub_class_id'] = this.selectSubClass
		} else {
			delete this.searchObj['sub_class_id'];
		}
		if ((this.createdFromDate && !this.createdToDate) || (!this.createdFromDate && this.createdToDate)) {
			if (!this.createdFromDate) {
				let msg = this.commonService.domDirectionLTR ? "Please select 'From Date'." : 'الرجاء تحديد "من التاريخ".';
				this.toastService.displayToast(msg, null, 'danger');
			}
			if (!this.createdToDate) {
				let msg = this.commonService.domDirectionLTR ? "Please select 'To Date'." : 'الرجاء تحديد "حتى الآن".';
				this.toastService.displayToast(msg, null, 'danger');
			}
			return
		}
		if (this.createdFromDate && this.createdToDate) {
			this.searchObj['start_date'] = this.datePipe.transform(this.createdFromDate, 'yyyy-MM-dd');
			this.searchObj['end_date'] = this.datePipe.transform(this.createdToDate, 'yyyy-MM-dd');
		} else {
			delete this.searchObj['start_date'];
			delete this.searchObj['end_date'];
		}
		if (this.searchObj['sub_class_id'] || (this.searchObj['start_date'] || this.searchObj['end_date'])) {
			this.getInventoryList(this.searchObj);
		}
	}

	// search text parameter in api
	search() {
		this.filterparams = {
			page: this.pageno,
			limit: this.pagesize
		}
		if (this.searchText && this.searchText.trim() == '') {
			this.searchText = null;
			return
		}
		if (this.searchText) {
			this.searchObj['search'] = this.searchText.trim();
		}
		this.getInventoryList(this.searchObj);
	}

	clearSearch() {
		this.searchText = null;
		delete this.searchObj['search']
		this.getInventoryList(this.searchObj, true);
	}

	getSubclass() {
		this.subscription.add(
			this.dataService.get(URLS.subClassList).subscribe(data => {
				if (data['code'] == 200) {
					let list = data['data'] && data['data'].length ? data['data'] : [];
					this.subClassesList = [];
					list.forEach(element => {
						this.subClassesList.push({
							id: element.sub_class_id,
							name: this.commonService.domDirectionLTR ? element.sub_class_name_en ? element.sub_class_name_en : '-' : element.sub_class_name_ar ? element.sub_class_name_ar : '-'
						})
					});

				} else {
					if (data['code'] != 204) {
						this.toastService.displayToast(data['message'], null, 'danger');
					}
				}
			}, error => {
				this.toastService.displayToast(error['message'], null, 'danger');
			})
		)
	}

	clearFilter() {
		delete this.searchObj['sub_class_id'];
		delete this.searchObj['start_date'];
		delete this.searchObj['end_date'];
		this.createdFromDate = null;
		this.selectSubClass = null;
		this.createdToDate = null;
		this.dateRange.reset();
		this.getInventoryList(this.searchObj, true)
	}
	onDateChange(datetime) {
		setTimeout(() => {
			datetime.cancel(true);
		}, 300)
	}
}
