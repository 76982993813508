import { DOCUMENT, DatePipe } from '@angular/common';
import { Component, ElementRef, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { URLS } from '../../_config/api.config';
import { ApiService } from '../../_services/api-data.service';
import { CommonService } from '../../_services/common.service';
import { ModalService } from '../../_services/modal.service';
import { ToastService } from '../../_services/toast.service';
import { MassUploadComponent } from '../../components/mass-upload/mass-upload.component';
import { addDiscountComponent } from '../add-discount/add-discount.component';

@Component({
	selector: 'app-product-discount-management',
	templateUrl: './product-discount-management.component.html',
	styleUrls: ['./product-discount-management.component.scss'],
})
export class ProductDiscountManagementComponent implements OnInit {
	public subscription = new Subscription();
	currencySymbol: string = "$";
	showSearch = false;
	showFilter = false;
	fromDate: any;
	toDate: any;
	statusSelect: any;
	isLoading: boolean = false;
	searchText: any;
	showLoader: boolean = false;
	discountListData: any[] = [];
	totalDiscountCount: number = 0;
	pageno = 1;
	pagesize = 10;
	filterparams: any;
	searchObj: any;
	statusList = [];
	maxFromDate: any;
	maxDate: any;
	minToDate: any;
	todaysDate: any = new Date();

	perPageInterface = { cssClass: 'custom-select-dd per-page-dd' };
	selectInterface = { cssClass: 'custom-select-dd' };
	public posts = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25];

	public count = 0;

	public itemsPerPage = 5;
	public currentPage = 1;
	@Input() showHeader: boolean;
	dateRange: FormGroup
	constructor(
		private modalService: ModalService,
		public commonService: CommonService,
		private modalCtrl: ModalController,
		private alertController: AlertController,
		private dataService: ApiService,
		private toastService: ToastService,
		private fb: FormBuilder,
		private targetElem: ElementRef,
		private router: Router,
		private datePipe: DatePipe,
		@Inject(DOCUMENT) private document: Document,
		private translate: TranslateService
	) { }

	ngOnInit() {
		const element = this.document.querySelector('#scrollId');
		if (element) {
			element.scrollIntoView();
		}

		let date = new Date();
		this.maxFromDate = date.setDate(this.todaysDate.getDate() - 1);
		this.maxDate = this.datePipe.transform(this.todaysDate, 'YYYY-MM-dd');
		this.maxFromDate = this.datePipe.transform(this.maxFromDate, 'YYYY-MM-dd');
		this.todaysDate = this.todaysDate.toISOString();
		this.dateRange = this.fb.group({
			fromDate: new FormControl(null),
			toDate: new FormControl(null),
		})

		this.searchText = null;
		this.filterparams = {
			page: this.pageno,
			size: this.pagesize
		}
		this.statusList = [
			{
				value: "",
				name: this.commonService.domDirectionLTR ? 'All' : 'الجميع',
			},
			{
				value: "true",
				name: this.commonService.domDirectionLTR ? 'Active' : 'نشيط',
			},
			{
				value: "false",
				name: this.commonService.domDirectionLTR ? 'Inactive' : 'غير نشط'
			}
		];
		this.getDiscountList(this.filterparams, false);
	}

	ionViewWillEnter() {
		this.showHeader = true;
	}

	openFilters() {
		this.showFilter = !this.showFilter;
	}

	openMassUploadModal() {
		let options: any = {
			id: "massUploadModal"
		};
		options['component'] = MassUploadComponent;
		this.modalService.openModal(options);
	}

	async addDiscount(data?) {
		const modal = await this.modalCtrl.create({
			component: addDiscountComponent,
			cssClass: 'product-discount-modal',
			backdropDismiss: false,
			componentProps: { discountData: data ? data : null }
		});
		modal.present();
		modal.onDidDismiss().then((data) => {
			if (data && data.data) {
				this.filterparams = {
					page: this.pageno,
					size: this.pagesize
				}
				this.getDiscountList(this.filterparams, false)
			}
		})
	}

	search() {
		this.filterparams = {
			page: this.pageno,
			size: this.pagesize
		}
		if (this.searchText && this.searchText.trim() == '') {
			this.searchText = null;
			return
		}
		if (this.searchText) {
			this.filterparams['search'] = this.searchText.trim();
			this.getDiscountList(this.filterparams, false);
		}
	}

	applyBtn() {
		this.searchObj = {}
		if ((this.fromDate && !this.toDate) || (!this.fromDate && this.toDate)) {
			if (!this.fromDate) {
				this.toastService.displayToast(this.translate.instant('error.selectFromDate'), null, 'danger');
			}
			if (!this.toDate) {
				this.toastService.displayToast(this.translate.instant('error.selectToDate'), null, 'danger');
			}
			return
		}
		if (this.fromDate && this.toDate) {
			this.searchObj['start_date'] = this.datePipe.transform(this.fromDate, 'yyyy-MM-dd');
			this.searchObj['end_date'] = this.datePipe.transform(this.toDate, 'yyyy-MM-dd');
		}
		if (this.searchObj['start_date'] && this.searchObj['end_date']) {
			if (this.searchObj['start_date'] > this.searchObj['end_date']) {
				this.toastService.displayToast("'From Date' cannot be greater than 'To Date'.", null, 'danger');
				return
			}
		}

		this.searchObj['page'] = this.pageno;
		this.searchObj['size'] = this.pagesize;
		setTimeout(() => {
			this.getDiscountList(this.searchObj, false);
		}, 300);

	}

	// select data per page
	selectPageLength(event) {
		let selectPageSize = event.detail.value;
		this.itemsPerPage = event.detail.value
		this.pagesize = selectPageSize;
		this.currentPage = 1;
		this.filterparams['page'] = 1;
		this.filterparams['size'] = selectPageSize;
		this.getDiscountList(this.filterparams, false)
	}

	onPageChange(event): void {
		this.currentPage = event;
		this.filterparams['page'] = this.currentPage;
		this.filterparams['size'] = this.itemsPerPage;
		this.getDiscountList(this.filterparams, false)
	}

	clearSearch() {
		this.searchText = null;
		let params = {
			page: 1,
			size: this.pagesize
		}
		this.getDiscountList(params, false);
	}

	clearFilter() {
		this.fromDate = null;
		this.toDate = null;
		this.statusSelect = null;
		this.dateRange.reset();
		this.searchObj = {};
		setTimeout(() => {
			this.getDiscountList(this.searchObj, true);
		}, 300);
	}

	onDateChange(datetime) {
		setTimeout(() => {
			datetime.cancel(true);
		}, 300)
	}

	async presentAlert(discount, enddate) {
		let endingDate: any;
		if (enddate) {
			endingDate = this.datePipe.transform(enddate, 'yyyy-MM-dd')
		}
		if (endingDate < this.todaysDate) {
			return
		}
		discount.isActive = !discount.isActive;
		let deactivateMsg = this.commonService.domDirectionLTR ? "You want to deactivate product discount?" : "تريد إلغاء تنشيط خصم المنتج؟";
		let activateMsg = this.commonService.domDirectionLTR ? "You want to activate product discount?" : "تريد تفعيل خصم المنتج؟";
		const alert = await this.alertController.create({
			header: this.commonService.domDirectionLTR ? 'Are you sure?' : 'هل أنت متأكد؟',
			message: discount.isActive ? deactivateMsg : activateMsg,
			buttons: [
				{
					text: this.commonService.domDirectionLTR ? 'Cancel' : 'يلغي',
					role: 'cancel',
					handler: () => {

					},
				},
				{
					text: this.commonService.domDirectionLTR ? 'Yes' : 'نعم',
					role: 'confirm',
					handler: () => {
						let isActive;
						if (discount.isActive) {
							isActive = false;
						} else {
							isActive = true;
						}
						let params = {
							is_active: isActive,
							discount_id: discount.discount_id.toString(),
						};
						this.updateDiscount(params, discount);
					},
				},
			],
		});
		await alert.present();
		const { role } = await alert.onDidDismiss();
	}

	/////////// Update Discount data /////////////
	updateDiscount(params: any, discountData?) {

		this.isLoading = true;
		this.subscription.add(
			this.dataService.post(URLS.discountUpdate, params).subscribe(data => {
				if (data['code'] == 200 && data['data']) {
					this.isLoading = false;
					discountData.isActive = !discountData.isActive;
					this.toastService.displayToast(data['message'], null, 'success');
				} else {
					this.isLoading = false;
					this.toastService.displayToast(data['message'], null, 'danger');
				}
			}, error => {
				this.isLoading = false;
				this.toastService.displayToast(error['message'], null, 'danger');
			})
		)
	}

	//////////// Get Discount List //////////////
	getDiscountList(params?, clearFilter?: boolean) {
		this.showLoader = true;
		this.discountListData = [];
		if (this.searchObj) {
			params = { ...this.searchObj, ...params }
		}
		if (clearFilter) {
			params = {};
			params['page'] = this.pageno;
			params['size'] = this.pagesize;
		}
		this.subscription.add(
			this.dataService.get(URLS.discountList, params).subscribe(data => {
				if (data['code'] == 200) {
					this.totalDiscountCount = parseInt(data['data']['total_count'])
					this.discountListData = [];
					if (data['data'] && data['data']['result'].length) {
						let list = data['data'] && data['data']['result'].length ? data['data']['result'] : [];
						list.forEach((discount) => {
							let obj = {
								discount_id: discount.discount_id ? discount.discount_id : '',
								discount_title: this.commonService.domDirectionLTR ? discount.discount_title_en ? discount.discount_title_en : '-' : discount.discount_title_ar ? discount.discount_title_ar : '-',
								discount_description: this.commonService.domDirectionLTR ? discount.discount_description_en ? discount.discount_description_en : '-' : discount.discount_description_ar ? discount.discount_description_ar : '-',
								web_image_url: discount.image_url_en ? discount.image_url_en : '',
								start_date: discount.start_date ? discount.start_date : '-',
								end_date: discount.end_date ? discount.end_date : '-',
								created_at: discount.created_at ? discount.created_at : '-',
								updated_at: discount.updated_at ? discount.updated_at : '-',
								discount_type: discount.discount_type ? discount.discount_type : '-',
								discount_value: discount.discount_value ? discount.discount_value : '-',
								isActive: discount.is_active,
							};
							this.discountListData.push(obj);
						});
					}
					this.showLoader = false;
				} else {
					this.showLoader = false;
					this.toastService.displayToast(data['message'], null, 'danger');
				}
			}, error => {
				this.showLoader = false;
				this.discountListData = [];
				this.toastService.displayToast(error['message'], null, 'danger');
			})
		)
	}

	editProductDetails(discountId: any, from: any) {
		const navigationExtras: NavigationExtras = {
			queryParams: {
				discount_id: discountId,
				from: from,
			},
		};
		this.router.navigate(['account-details/product-discount-management/product-discount-details'], navigationExtras);
	}

	key: string = "id";
	sortType: string = "ASC";
	onSort(event, key: any) {
		if (!this.discountListData.length) {
			return;
		}
		this.key = key;

		const element = document.querySelectorAll('.sorting-icon');
		element.forEach(img => {
			img.setAttribute("src", "assets/icon/sort.svg")
		});

		let eventValue;
		if (event.target.className.includes("column-heading")) {
			eventValue = event.target.children[0];
		} else {
			eventValue = event.srcElement;
		}
		let order = eventValue.getAttribute("data-order")
		if (this.filterparams['sort_by'] != key) {
			order = "asc"
		}
		if (order === "asc") {
			this.sortType = "ASC";
			eventValue.setAttribute("data-order", "desc");
			eventValue.src = "assets/icon/sorting_asc.svg";
		} else if (order === "desc") {
			this.sortType = "DESC";
			eventValue.setAttribute("data-order", "");
			eventValue.src = "assets/icon/sorting_desc.svg";
		} else {
			delete this.filterparams['sort_by'];
			delete this.filterparams['sort_type'];
			eventValue.setAttribute("data-order", "asc");
			eventValue.src = "assets/icon/sort.svg";

		}
		if (order) {
			this.filterparams['sort_by'] = key;
			this.filterparams['sort_type'] = this.sortType;
		}

		this.getDiscountList(this.filterparams, false)

	}

}
