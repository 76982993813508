import { DOCUMENT, DatePipe } from '@angular/common';
import { Component, ElementRef, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { URLS } from 'src/app/_config/api.config';
import { CommonService } from '../../_services/common.service';
import { ModalService } from '../../_services/modal.service';
import { ApiService } from './../../_services/api-data.service';
import { ToastService } from './../../_services/toast.service';

@Component({
	selector: 'app-coupon-management',
	templateUrl: './coupon-management.component.html',
	styleUrls: ['./coupon-management.component.scss'],
})
export class CouponManagementComponent implements OnInit {
	currencySymbol: string = "$";
	fromDate: any;
	toDate: any;
	isLoading: boolean = false;
	showFilter: boolean = false;
	showSearch: boolean = false;
	searchText: any;
	showLoader: boolean = false;
	pageno = 1;
	pagesize = 10;
	filterparams: any;
	statusSelect: any = 'all';
	perPageInterface = { cssClass: 'custom-select-dd per-page-dd' };
	selectInterface = { cssClass: 'custom-select-dd' };
	public posts = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25];

	public count = 0;
	public itemsPerPage = 10;
	public currentPage = 1;
	actionList = [];
	statusList = [];

	columnHeaders = {
		order_id: 'Order #',
		date: 'Date',
		ship_to: 'Ship to',
		order_total: 'Order total',
		status: 'Status',

	}
	couponList = [];
	@Input() showHeader: boolean;
	dateRange: FormGroup;
	maxFromDate: any;
	minToDate: any;
	todaysDate = new Date();
	maxDate: any;
	statusFilter = 'all';
	totalCount = 0;
	searchObj: any;

	private subscription: Subscription = new Subscription();

	constructor(
		private modalService: ModalService,
		private fb: FormBuilder,
		public commonService: CommonService,
		private router: Router,
		private targetElem: ElementRef,
		private alertController: AlertController,
		private datePipe: DatePipe,
		private toastService: ToastService,
		private dataService: ApiService,
		private translate: TranslateService,

		@Inject(DOCUMENT) private document: Document,
	) { }

	ngOnInit() {
		const element = this.document.querySelector('#scrollId');
		if (element) {
			element.scrollIntoView();
		}

		this.statusList = [
			{ value: 'all', label: this.commonService.domDirectionLTR ? 'All' : 'الجميع' },
			{ value: 'APPROVED', label: this.commonService.domDirectionLTR ? 'Approved' : 'وافق' },
			{ value: 'PENDING', label: this.commonService.domDirectionLTR ? 'Pending' : 'قيد الانتظار' },
			{ value: 'REJECTED', label: this.commonService.domDirectionLTR ? 'Rejected' : 'مرفوض' }
		]

		this.actionList = [
			{ value: 'APPROVED', name: this.translate.instant('approved'), disabled: false },
			{ value: 'PENDING', name: this.translate.instant('pending'), disabled: true },
			{ value: 'REJECTED', name: this.translate.instant('rejected'), disabled: false }
		]


		this.dateRange = this.fb.group({
			fromDate: new FormControl(null),
			toDate: new FormControl(null),
		})
		let date = new Date();
		this.maxFromDate = date.setDate(this.todaysDate.getDate() - 1);
		this.maxDate = this.datePipe.transform(this.todaysDate, 'YYYY-MM-dd');
		this.maxFromDate = this.datePipe.transform(this.maxFromDate, 'YYYY-MM-dd');

		this.searchText = null;
		this.filterparams = {
			page: this.pageno,
			limit: this.pagesize
		}

		this.getCoupounList(this.filterparams, false);
	}

	getCoupounList(params?, clearFilter?: boolean) {
		this.showLoader = true;
		this.couponList = [];
		this.totalCount = 0;
		if (this.searchObj) {
			params = { ...this.searchObj, ...params }
		}
		if (clearFilter) {
			params = {};
			params['page'] = this.pageno;
			params['limit'] = this.pagesize;
		}
		this.subscription.add(
			this.dataService.get(URLS.couponList, params).subscribe(data => {
				if (data['code'] == 200) {
					this.couponList = [];
					this.totalCount = parseInt(data['data']['total_count'])
					this.couponList = data['data']['result'];
					this.showLoader = false;
				} else {
					this.showLoader = false;
					this.toastService.displayToast(data['message'], null, 'danger');
				}
			}, error => {
				this.showLoader = false;
				this.couponList = [];
				this.toastService.displayToast(error['message'], null, 'danger');
			})
		)
	}

	ionViewWillEnter() {
	}

	onDateChange(datetime) {
		setTimeout(() => {
			datetime.cancel(true);
		}, 300)
	}

	openFilters() {
		this.showFilter = !this.showFilter;
	}

	// select data per page
	selectPageLength(event) {
		let selectPageSize = event.detail.value;
		this.itemsPerPage = event.detail.value
		this.pagesize = selectPageSize;
		this.currentPage = 1;
		this.filterparams['page'] = 1;
		this.filterparams['limit'] = selectPageSize;
		this.getCoupounList(this.filterparams, false)
	}

	onPageChange(event): void {
		this.currentPage = event;
		this.filterparams['page'] = this.currentPage;
		this.filterparams['limit'] = this.itemsPerPage;
		this.getCoupounList(this.filterparams, false)
	}

	search() {
		this.filterparams = {
			page: this.pageno,
			limit: this.pagesize
		}
		if (this.searchText && this.searchText.trim() == '') {
			this.searchText = null;
			return
		}
		if (this.searchText) {
			this.filterparams['search'] = this.searchText.trim();
			this.getCoupounList(this.filterparams, false);
		}
	}

	async presentAlert(event) {
		let mesaage = this.commonService.domDirectionLTR ? "You want to delete this?" : "تريد حذف هذا";
		const alert = await this.alertController.create({
			header: this.commonService.domDirectionLTR ? 'Are you sure?' : 'هل أنت متأكد؟',
			message: mesaage,
			buttons: [
				{
					text: this.commonService.domDirectionLTR ? 'Cancel' : 'يلغي',
					role: 'cancel',
					cssClass: 'secondary',
					handler: () => {
					},
				},
				{
					text: this.commonService.domDirectionLTR ? 'Yes' : 'نعم',
					role: 'confirm',
					handler: () => {
					},
				},
			],
		});

		await alert.present();

		const { role } = await alert.onDidDismiss();

	}

	detailPage(id) {
		this.router.navigate(['account-details/coupon-management/coupon-details', id]);
	}

	key: string = "id";
	sortType: string = "ASC";
	onSort(event, key: any) {

		if (!this.couponList.length) {
			return;
		}
		this.key = key;
		const element = document.querySelectorAll('.sorting-icon');
		element.forEach(img => {
			img.setAttribute("src", "assets/icon/sort.svg")
		});

		let eventValue;
		if (event.target.className.includes("column-heading")) {
			eventValue = event.target.children[0];
		} else {
			eventValue = event.srcElement;
		}
		let order = eventValue.getAttribute("data-order")
		if (this.filterparams['sort_by'] != key) {
			order = "asc"
		}
		if (order === "asc") {
			this.sortType = "ASC";
			eventValue.setAttribute("data-order", "desc");
			eventValue.src = "assets/icon/sorting_asc.svg";
		} else if (order === "desc") {
			this.sortType = "DESC";
			eventValue.setAttribute("data-order", "");
			eventValue.src = "assets/icon/sorting_desc.svg";
		} else {
			delete this.filterparams['sort_by'];
			delete this.filterparams['sort_type'];
			eventValue.setAttribute("data-order", "asc");
			eventValue.src = "assets/icon/sort.svg";

		}
		if (order) {
			this.filterparams['sort_by'] = key;
			this.filterparams['sort_type'] = this.sortType;
		}

		this.getCoupounList(this.filterparams, false)

	}

	onStatusChange(event: any, couponId) {
		let params = {};
		if (couponId) {
			params["coupon_id"] = Number(couponId);
		}
		if (event.value) {
			params["approval_status"] = event.value;
		}
		if (couponId && event.value)
			this.updateStatus(params);
	}

	/////////// Update Product Review Status /////////////
	updateStatus(params: any) {

		this.isLoading = true;
		this.subscription.add(
			this.dataService.put(URLS.updateCoupon, params).subscribe(data => {
				if (data['code'] == 200 && data['data']) {
					this.isLoading = false;
				} else {
					this.isLoading = false;
					this.toastService.displayToast(data['message'], null, 'danger');
				}
			}, error => {
				this.isLoading = false;
				this.toastService.displayToast(error['message'], null, 'danger');
			})
		)
	}

	applyBtn() {
		this.searchObj = {}
		if ((this.fromDate && !this.toDate) || (!this.fromDate && this.toDate)) {
			if (!this.fromDate) {
				this.toastService.displayToast(this.translate.instant('error.selectFromDate'), null, 'danger');
			}
			if (!this.toDate) {
				this.toastService.displayToast(this.translate.instant('error.selectToDate'), null, 'danger');
			}
			return
		}
		if (this.fromDate && this.toDate) {
			this.searchObj['start_date'] = this.datePipe.transform(this.fromDate, 'yyyy-MM-dd');
			this.searchObj['end_date'] = this.datePipe.transform(this.toDate, 'yyyy-MM-dd');
		}
		if (this.searchObj['start_date'] && this.searchObj['end_date']) {
			if (this.searchObj['start_date'] == this.searchObj['end_date']) {
				this.toastService.displayToast("'From Date' and 'To Date' cannot be same.", null, 'danger');
				return
			}
		}

		if (this.statusFilter != 'all') {
			this.searchObj['approval_status'] = this.statusFilter;
		}

		this.searchObj['page'] = this.pageno;
		this.searchObj['limit'] = this.pagesize;
		setTimeout(() => {
			this.getCoupounList(this.searchObj, false);
		}, 300);

	}



	clearFilter() {
		this.fromDate = null;
		this.toDate = null;
		this.statusFilter = 'all';
		this.searchObj = {}
		this.dateRange.reset();
		setTimeout(() => {
			this.getCoupounList(this.searchObj, true);
		}, 300);
	}


	clearSearch() {
		this.searchText = null;
		this.searchObj = {}
		this.filterparams = {
			page: 1,
			limit: this.pagesize
		}
		let params = {
			page: 1,
			limit: this.pagesize
		}
		this.getCoupounList(params, false);
	}

}
