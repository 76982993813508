import { environment } from '../../environments/environment';

export const URLS = Object({
	// Authentication
	login: `${environment.apiUrl}/supplier/login`,
	logout: `${environment.apiUrl}/supplier/logout`,
	resendOtp: `${environment.apiUrl}/supplier/resend/otp`,
	generateOtp: `${environment.apiUrl}/supplier/generateOtp`,
	verifyOtp: `${environment.apiUrl}/supplier/verifyOtp`,
	resetPassword: `${environment.apiUrl}/supplier/reset/password`,
	changePassword: `${environment.apiUrl}/supplier/change/password`,
	update2fa: `${environment.apiUrl}/supplier/2FA/update`,

	// Registration
	registration: `${environment.apiUrl}/supplier/registration`,

	/////// company details
	addCompanyDetails: `${environment.apiUrl}/supplier/addCompanyDetails`,

	// product listing
	productList: `${environment.apiUrl}/supplier/product/get`,
	activeSubclass: `${environment.apiUrl}/active/subclasses`,
	generateTemplate: `${environment.apiUrl}/supplier/generate/product-import/csv`,
	bulkUpload: `${environment.apiUrl}/supplier/product/import`,

	//Dashboard
	getDashboardCount: `${environment.apiUrl}/count/all/get`,
	getMyVat: `${environment.apiUrl}/my/vat`,
	getMyVatHistory: `${environment.apiUrl}/my/vat/history`,

	//Add product
	// consolidatedGroup: `${environment.apiUrl}/supplier/groupList`,
	consolidatedCategories: `${environment.apiUrl}/supplier/categories`,
	subclassDetails: `${environment.apiUrl}/supplier/getSubclassDetails`,
	configurationDetails: `${environment.apiUrl}/supplier/configurationDetails`,
	regionsList: `${environment.apiUrl}/master/regions/list`,
	countryList: `${environment.apiUrl}/master/countries/list`,
	cityList: `${environment.apiUrl}/master/cities/list`,
	stockAvail: `${environment.apiUrl}/supplier/product/getAvail`,
	brands: `${environment.apiUrl}/supplier/brand/list`,
	addProduct: `${environment.apiUrl}/supplier/product/add`,
	updateProduct: `${environment.apiUrl}/supplier/updateProduct`,
	addImage: `${environment.apiUrl}/supplier/product/imageupload`,
	deleteImage: `${environment.apiUrl}/product/image/delete`,
	updateImage: `${environment.apiUrl}/supplier/product/update`,
	units: `${environment.apiUrl}/master/units/list`,
	productDetails: `${environment.apiUrl}/supplier/product/details`,
	activeWarehouse: `${environment.apiUrl}/active/warehouses`,
	activeDocumentList: `${environment.apiUrl}/active/documents/list`,
	addDocument: `${environment.apiUrl}/add/product/documents`,
	updateDocument: `${environment.apiUrl}/update/product/documents`,
	addProductInventory: `${environment.apiUrl}/add/product/inventory`,
	getPreview: `${environment.apiUrl}/supplier/product/preview`,
	replaceProduct: `${environment.apiUrl}/supplier/product/replace`,

	// warehouse
	warehouseList: `${environment.apiUrl}/warehouse/list`,
	warehouseAdd: `${environment.apiUrl}/warehouse/add`,
	warehouseDetails: `${environment.apiUrl}/warehouse/details`,
	warehouseUpdate: `${environment.apiUrl}/warehouse/update`,

	// My Profile
	supplierDetails: `${environment.apiUrl}/supplier/details`,
	supplierUpdate: `${environment.apiUrl}/supplier/update`,
	addBankDetails: `${environment.apiUrl}/supplier/bank/details/add`,

	//brand mnagment
	brandList: `${environment.apiUrl}/supplier/brand/list`,
	addBrand: `${environment.apiUrl}/supplier/brand/add`,
	viewBrand: `${environment.apiUrl}/supplier/brand/details`,
	updateBrand: `${environment.apiUrl}/supplier/brand/update`,

	// Product Review
	supplierReview: `${environment.apiUrl}/supplier/review/get`,
	reviewUpdate: `${environment.apiUrl}/supplier/review/update`,
	reviewDetails: `${environment.apiUrl}/supplier/review/reviewbyid`,

	// Featured product
	featuredList: `${environment.apiUrl}/get/product/featured`,
	addFeaturedProduct: `${environment.apiUrl}/add/product/featured`,
	updateFeaturedProduct: `${environment.apiUrl}/update/product/featured`,

	// Order Management
	orderList: `${environment.apiUrl}/supplier/order/list`,
	orderDetails: `${environment.apiUrl}/supplier/order/detail`,

	// Product Discount Management
	discountList: `${environment.apiUrl}/discount/list`,
	discountDetails: `${environment.apiUrl}/discount/details`,
	subClassList: `${environment.apiUrl}/supplier/active/subclass`,
	subClassProduct: `${environment.apiUrl}/subclass/product`,
	discountAdd: `${environment.apiUrl}/discount/add`,
	discountUpdate: `${environment.apiUrl}/discount/update`,
	deleteDiscountedProduct: `${environment.apiUrl}/discount/product/delete`,

	// Notifications
	getNotifications: `${environment.apiUrl}/supplier/notification/settings/get`,
	updateNotifications: `${environment.apiUrl}/supplier/notification/settings/update`,
	notificationListType: `${environment.apiUrl}/supplier/notification/types`,
	notificationList: `${environment.apiUrl}/supplier/notification/get`,
	updateNotificationStatus: `${environment.apiUrl}/supplier/notification/status/update`,

	// FAQ
	getFAQs: `${environment.apiUrl}/cms/faqs/get`,

	//CMS
	getCMSdata: `${environment.apiUrl}/customer/cms/list`,

	//contact us
	contactUs: `${environment.apiUrl}/customer/enquiry/add`,

	//inventory management
	inventoryList: `${environment.apiUrl}/supplier/inventory/list`,
	updateProductQuantity: `${environment.apiUrl}/supplier/inventory/update`,

	//coupon management
	transactionList: `${environment.apiUrl}/supplier/transaction/list`,

	//coupon management
	couponList: `${environment.apiUrl}/supplier/coupon/list`,
	couponDetail: `${environment.apiUrl}/supplier/coupon/details`,
	updateCoupon: `${environment.apiUrl}/coupon/status/update`,

	// earing management

	earningDetails: `${environment.apiUrl}/earnings/details`,

	cancelOrderList: `${environment.apiUrl}/supplier/order/cancel`,
	returnOrderList: `${environment.apiUrl}/supplier/order/return`,
	orderStatusChange: `${environment.apiUrl}/supplier/update/approval/status`,

	//Related Products
	// categories: `${environment.apiUrl}/active/categories`,
	categories: `${environment.apiUrl}/active/subclasses`,
	relatedProductList: `${environment.apiUrl}/supplier/related/product/get`,
	addRelatedProduct: `${environment.apiUrl}/supplier/related/product/add`,
	updateMetaTag: `${environment.apiUrl}/supplier/product/tag/add`,

	//price list
	productPriceList: `${environment.apiUrl}/admin/product/price/list`,

});

export const SECRET_KEY: string = `${environment.rsa_public_key}`;

